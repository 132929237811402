import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Logo from '../imgs/logo.png';

export function Navbar() {
   const [prevScrollPos, setPrevScrollPos] = useState(0);
   const [visible, setVisible] = useState(true);
   const location = useLocation();

   useEffect(() => {
       const handleScroll = () => {
           const currentScrollPos = window.pageYOffset;
           setVisible((prevScrollPos > currentScrollPos && currentScrollPos > 0) || currentScrollPos < 10);
           setPrevScrollPos(currentScrollPos);
       };

       window.addEventListener('scroll', handleScroll);
       return () => window.removeEventListener('scroll', handleScroll);
   }, [prevScrollPos, visible]);

   return (
       <nav className={`bg-[#F5F4EC] fixed w-full transition-transform duration-300 ease-in-out z-50 ${visible ? 'translate-y-0' : '-translate-y-full'}`}>
           <div className="container mx-auto px-4 sm:px-6 lg:px-8">
               <div className="flex justify-between items-center h-16">
                   {/* Left side (Logo) */}
                   <div className="flex items-center">
                       <a href="/" className="flex items-center space-x-2">
                           <img src={Logo} alt="Logo" className="h-8 w-auto" />
                           <span className="font-sans font-medium text-xl text-[rgb(10,11,87)]">Sathvik</span>
                       </a>
                   </div>
                   
                   {/* Right side (Links) */}
                   <div className="flex items-center">
                       <ul className="flex space-x-6 font-sans font-medium text-lg text-[rgb(10,11,87)]">
                           <li>
                               <a
                                   href="/"
                                   className={location.pathname === '/' ? 'underline' : ''}
                               >
                                   WORK
                               </a>
                           </li>
                           <li>
                               <a
                                   href="/about"
                                   className={location.pathname === '/about' ? 'underline' : ''}
                               >
                                   ABOUT
                               </a>
                           </li>
                       </ul>
                   </div>
               </div>
           </div>
       </nav>
   );
}