import React, { useEffect } from 'react';
import PhoneScroll from './iPhoneScroll';
import MainGirlPinkSVG from '../imgs/GIRLFINAL.svg'
import MainGuyPinkSVG from '../imgs/GUYFINAL.svg'
import PodMainSVG from '../imgs/PODFINAL.svg'



export default function Main() {
  useEffect(() => {
    // Set the zoom level to 50% when the component mounts
    document.body.style.zoom = "40%";
    
    // Set the background color and ensure it covers the entire viewport
    document.body.style.backgroundColor = "#121212";
    document.body.style.margin = "0";
    document.body.style.padding = "0";
    document.body.style.minHeight = "100vh";
    document.body.style.overflow = "auto";
    
    // Clean up function to reset styles when component unmounts
    return () => {
      document.body.style.zoom = "100%";
      document.body.style.backgroundColor = "";
      document.body.style.margin = "";
      document.body.style.padding = "";
      document.body.style.minHeight = "";
      document.body.style.overflow = "";
    };
  }, []);

  const mainContainerStyle = {
    width: '1439px',
    minHeight: '23945px',
    backgroundColor: '#121212',
    position: 'relative',
    overflow: 'hidden',
    margin: '0 auto',
    padding: '0',
  };

  const phoneContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: `${60}px`,
    margin: `${50}px 0 ${100}px 0`,
    transformOrigin: 'center',
  };

  const instructionStyle = {
    fontStyle: 'italic',
    color: 'white',
    fontSize: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };



  return (
    <div style={mainContainerStyle}>
      <div className='w-[1160px] h-[580px] bg-[url(./assets/images/b54d2b49-f9d5-451a-928c-1e1c035c7f4d.png)] bg-[length:100%_100%] bg-no-repeat relative z-[651] mt-[99px] mr-0 mb-0 ml-[169px]' />
      <div className='w-[1047px] h-[124px] bg-[url(./assets/images/titleblue.png)] bg-[length:100%_100%] bg-no-repeat relative z-[647] mt-[87px] mr-0 mb-0 ml-[196px]'>
        <span className="flex h-[84px] justify-start items-start font-['Helvetica_Neue'] text-[60px] font-bold leading-[84px] text-[#fff] absolute top-[20px] left-[407px] text-left whitespace-nowrap z-[649]">
          Process
        </span>
      </div>
      <div className='w-[1047px] h-[124px] bg-[url(./assets/images/1080207b-9f44-4823-8209-e382c59f72ee.png)] bg-[length:100%_100%] bg-no-repeat relative z-[617] mt-[1074px] mr-0 mb-0 ml-[196px]'>
        <span className="flex h-[84px] justify-start items-start font-['Helvetica_Neue'] text-[60px] font-bold leading-[84px] text-[#fff] absolute top-[27px] left-[390px] text-left whitespace-nowrap z-[618]">
          Empathize
        </span>
      </div>
      <span className="block h-[56px] font-['Helvetica_Neue'] text-[40px] font-bold leading-[56px] text-[#fff] relative text-left whitespace-nowrap z-[610] mt-[25px] mr-0 mb-0 ml-[225px]">
        Research Strategy
      </span>
      <div className='w-[1020px] h-[400px] relative z-[613] mt-[13px] mr-0 mb-0 ml-[225px]'>
        <span className="flex w-[989px] h-[394px] justify-start items-start font-['Helvetica_Neue'] text-[22px] font-medium leading-[44px] text-[#fff] absolute top-0 left-0 text-left z-[609]">
          Before I delved into the data, it was important that I set out a
          research strategy so I could accurately get to the root causes of
          users' issues. One of the main reasons I opted for design thinking was
          because of the ambiguity surrounding a particular set of features that
          is heavily debated among Spotify users and heavy music listeners from
          other platforms (Apple Music, Amazon): social features. I went to
          where Spotify users were most active and honest about their
          experiences, and also conducted research with a test group to gather
          deeper insights to pinpoint the problem more precisely.
        </span>
        <span className="flex h-[56px] justify-start items-start font-['Helvetica_Neue'] text-[40px] font-bold leading-[56px] text-[#fff] absolute top-[344px] left-0 text-left whitespace-nowrap z-[613]">
          Secondary Research: Reddit Discussions on r/Spotify
        </span>
      </div>
      <div className='w-[1057px] h-[517px] relative z-[615] mt-[27px] mr-0 mb-0 ml-[225px]'>
        <div className="w-[524px] h-[517px] font-['Helvetica_Neue'] text-[22px] font-medium leading-[44px] absolute top-0 left-0 text-left z-[615]">
          <span className="font-['Helvetica_Neue'] text-[22px] font-medium leading-[44px] text-[#fff] relative text-left">
            The research consisted of 6 of the latest forum posts, within the
            last year, about social features on the most active and loyal base
            found on r/Spotify subreddit, a place where we can find high-quality
            information about the sentiment around Spotify’s social features. We
            recorded
          </span>
          <span className="font-['Helvetica_Neue'] text-[23px] font-bold leading-[44px] text-[#fff] relative text-left">
            141
          </span>
          <span className="font-['Helvetica_Neue'] text-[22px] font-medium leading-[44px] text-[#fff] relative text-left">
            
            individual user comments, with 27% exhibiting positive sentiment,
            31% exhibiting negative sentiment, and 42% being neutral. As the
            initial data did not provide conclusive reasons, I wanted to delve
            deeper to find patterns, so I broke up the 141 user comments into
            these specific reasons.
          </span>
        </div>
        <div className='w-[533px] h-[246px] bg-[url(./assets/images/b32479fb72cae8f561c5b6dba84733deff04df22.png)] bg-cover bg-no-repeat absolute top-[9px] left-[524px] z-[614]' />
      </div>
      <div className='w-[974px] h-[619px] bg-[url(./assets/images/bd534a468c24d7e7b4c1cf70ba01304edb1e5b30.png)] bg-cover bg-no-repeat relative z-[612] mt-[36px] mr-0 mb-0 ml-[207px]' />
      <div className='w-[1107px] h-[1621px] bg-[url(./assets/images/384c87a4-a746-4740-a250-e008a0287b48.png)] bg-cover bg-no-repeat relative overflow-hidden z-[619] mt-0 mr-0 mb-0 ml-[176px]' />
      <span className="block h-[83px] font-['Helvetica'] text-[40px] font-bold leading-[80px] text-[#fff] relative text-left whitespace-nowrap z-[606] mt-[12px] mr-0 mb-0 ml-[173px]">
        Primary Research: Survey & User Interviews:
      </span>
      <div className='w-[1107px] h-[617px] relative z-[605] mt-0 mr-0 mb-0 ml-[173px]'>
        <div className="w-[514px] h-[617px] font-['Helvetica_Neue'] text-[22px] font-medium leading-[44px] absolute top-0 left-0 text-left z-[605]">
          <span className="font-['Helvetica_Neue'] text-[22px] font-medium leading-[44px] text-[#fff] relative text-left">
            The primary research, involving{' '} 
          </span>
          <span className="font-['Helvetica_Neue'] text-[23px] font-bold leading-[44px] text-[#fff] relative text-left">
            65{' '}
          </span>
          <span className="font-['Helvetica_Neue'] text-[22px] font-medium leading-[44px] text-[#fff] relative text-left">
            Spotify and non-Spotify users, extends the insights from our
            secondary research on social features in music platforms. While
            secondary research identified high-level themes and formulated
            hypotheses, primary research aims to test these hypotheses,
            potentially revealing clearer trends and conclusive evidence. This
            symbiotic approach allows for validation of specific hypotheses
            derived from secondary research. By engaging directly with users, we
            combine broad market insights with specific user experiences,
            enabling a comprehensive understanding of social features in music
            platforms.
          </span>
        </div>
        <div className='w-[27.25%] h-[37.65%] bg-[url(./assets/images/7b3f28b273b4a9aef87a2a40d64f9e0b0dad63a2.png)] bg-cover bg-no-repeat absolute top-[1.94%] left-[72.75%] z-[602]' />
        <div className='w-[25.14%] h-[36.94%] bg-[url(./assets/images/ae651a51bc1c1bd1d700bcf98662d8c44a44f125.png)] bg-cover bg-no-repeat absolute top-[2.65%] left-[47.61%] z-[604]' />
        <div className='w-[52.19%] h-[47.72%] bg-[url(./assets/images/efb330514b868bfb72ab326f25f5c953b0ce5944.png)] bg-cover bg-no-repeat absolute top-[40.12%] left-[47.61%] z-[603]' />
      </div>
      <div className='w-[1047px] h-[124px] bg-[url(./assets/images/1f5363ae-0846-4050-bed9-67e46e566413.png)] bg-[length:100%_100%] bg-no-repeat relative z-[97] mt-[54px] mr-0 mb-0 ml-[177px]'>
        <span className="flex h-[67.74%] justify-start items-start font-['Helvetica_Neue'] text-[60px] font-bold leading-[84px] text-[#fff] absolute top-[21.77%] left-[37.25%] text-left whitespace-nowrap z-[98]">
          Define
        </span>
      </div>
      <span className="block h-[83px] font-['Helvetica'] text-[40px] font-bold leading-[80px] text-[#fff] relative text-left whitespace-nowrap z-[92] mt-[40px] mr-0 mb-0 ml-[181px]">
        Intrusive vs Non-intrusive Social Features
      </span>
      <span className="flex w-[1035px] h-[127px] justify-start items-start font-['Helvetica_Neue'] text-[22px] font-medium leading-[44px] text-[#fff] relative text-left z-[95] mt-0 mr-0 mb-0 ml-[181px]">
        Through our analysis of user behaviors and primary research, including
        user interviews, we have identified two primary categories that
        encapsulate the core motivations behind the opinions on social features
        in music streaming platforms.
      </span>
      <div className='w-[1065px] h-[437px] relative z-[100] mt-[28px] mr-0 mb-0 ml-[177px]'>
        <div className="w-[521px] h-[418px] font-['Helvetica_Neue'] text-[22px] font-medium leading-[44px] absolute top-0 left-0 text-left z-[99]">
          <span className="font-['Helvetica_Neue'] text-[25px] font-bold leading-[44px] text-[#fff] relative text-left">
            Intrusive social features: <br />
            <br />
          </span>
          <span className="font-['Helvetica_Neue'] text-[22px] font-medium leading-[44px] text-[#fff] relative text-left">
            Features that actively interrupt or demand the user's attention,
            often pushing social interactions to the forefront of the music
            listening experience. These features typically send frequent
            notifications, require immediate action, or prominently display
            social content that may distract from music playback.
          </span>
        </div>
        <div className="w-[519px] h-[437px] font-['Helvetica_Neue'] text-[22px] font-medium leading-[44px] absolute top-0 left-[546px] text-left z-[100]">
          <span className="font-['Helvetica_Neue'] text-[25px] font-bold leading-[50px] text-[#fff] relative text-left">
            Non-intrusive social features: <br />
          </span>
          <span className="font-['Helvetica_Neue'] text-[22px] font-medium leading-[44px] text-[#fff] relative text-left">
            <br />
            Features that enhance the social aspect of music listening without
            disrupting the core experience. These features are usually optional,
            can be easily ignored or disabled, and complement the music
            listening experience without overshadowing it. They allow users to
            connect with friends and discover new music through social
            interactions, but in a more passive and less distracting manner.
          </span>
        </div>
      </div>
      <div className='w-[1107px] h-[528px] relative z-[102] mt-[36px] mr-0 mb-0 ml-[172px]'>
        <span className="flex w-[555px] h-[528px] justify-start items-start font-['Helvetica_Neue'] text-[22px] font-medium leading-[44px] text-[#fff] absolute top-0 left-[552px] text-left z-[102]">
          1. Playlist collaboration (asynchronous).
          <br />
          2. Passive easier discovery of friends' public playlists.
          <br />
          3. Curated community playlists.
          <br />
          4. User discovery features.
          <br />
          5. Setting a activity status (similar to Whatsapp status feature).
          <br />
          6. Personalized music recommendations based on friends' tastes.
          <br />
          7. Optional social sharing of listening activity.
          <br />
          8. Sharing live listening statistics.
          <br />
          9. Friend activity sidebar (collapsible/hideable). <br />
          Display your music taste and artists for friends.
        </span>
        <span className="flex w-[547px] h-[495px] justify-start items-start font-['Helvetica_Neue'] text-[22px] font-medium leading-[44px] text-[#fff] absolute top-[11px] left-0 text-left overflow-hidden z-[101]">
          1. In-app messaging/DMs.
          <br />
          2. Song comments and replies.
          <br />
          3. Tiktok media-style feeds or timelines.
          <br />
          4. Real-time listening activity notifications.
          <br />
          5. Interactive story-like features (similar to <br />
          Instagram stories).
          <br />
          6. Live collaborative rooms for playlist editing.
          <br />
          7. Sending playlist compliments and/or suggestions. <br />
          8. Friends posting and status notifications.
          <br />
          9. Song reaction emojis or GIFs.
          <br />
          10. Friend activity pop-ups or overlay.
        </span>
      </div>
      <span className="block h-[83px] font-['Helvetica'] text-[40px] font-bold leading-[80px] text-[#fff] relative text-left whitespace-nowrap z-[94] mt-[82px] mr-0 mb-0 ml-[177px]">
        Metrics
      </span>
      <div className='flex w-[1092px] h-[661px] justify-between items-center relative z-[104] mt-[14px] mr-0 mb-0 ml-[177px]'>
        <div className="w-[557px] h-[660px] shrink-0 font-['Helvetica_Neue'] text-[22px] font-medium leading-[44px] relative text-left z-[103]">
          <span className="font-['Helvetica_Neue'] text-[22px] font-medium leading-[44px] text-[#fff] relative text-left">
            In the diagram below, respondents could select multiple intrusive or
            non-intrusive features (listed only features to avoid
          </span>
          <span className="font-['Helvetica_Neue'] text-[22px] font-bold leading-[44px] text-[#fff] relative text-left">
            wording bias
          </span>
          <span className="font-['Helvetica_Neue'] text-[22px] font-medium leading-[44px] text-[#fff] relative text-left">
            ). The total number of selections reflects how many features were
            chosen by both Spotify and Non-Spotify users.
            <br />
            <br />
            The total number of selections helps reveal social preferences:
            <br />
          </span>
          <span className="font-['Helvetica_Neue'] text-[22px] font-bold leading-[44px] text-[#fff] relative text-left">
            Non-intrusive selections:
          </span>
          <span className="font-['Helvetica_Neue'] text-[22px] font-medium leading-[44px] text-[#fff] relative text-left">
            
            A higher count indicates users prefer features that allow for social
            engagement without feeling overwhelmed or exposed.
            <br />
          </span>
          <span className="font-['Helvetica_Neue'] text-[22px] font-bold leading-[44px] text-[#fff] relative text-left">
            Intrusive selections:
          </span>
          <span className="font-['Helvetica_Neue'] text-[22px] font-medium leading-[44px] text-[#fff] relative text-left">
            A higher count reflects users' interest in highly visible or
            interactive features, such as real-time listening with friends.
          </span>
        </div>
        <div className="w-[497px] h-[661px] shrink-0 font-['Helvetica_Neue'] text-[22px] font-medium leading-[44px] relative text-left z-[104]">
          <span className="font-['Helvetica_Neue'] text-[22px] font-medium leading-[44px] text-[#fff] relative text-left">
            The AARRR framework tracks five key metrics for growth: Acquisition,
            Activation, Retention
          </span>
          <span className="font-['Helvetica_Neue'] text-[22px] font-medium leading-[44px] text-[#fff] relative text-left">
            ,
          </span>
          <span className="font-['Helvetica_Neue'] text-[22px] font-medium leading-[44px] text-[#fff] relative text-left">
            
            Referral, and Revenue.
          </span>
          <span className="font-['Helvetica_Neue'] text-[22px] font-medium leading-[44px] text-[#fff] relative text-left">
            The two metrics below are the ones I will be focusing on for
          </span>
          <span className="font-['Helvetica_Neue'] text-[22px] font-medium leading-[44px] text-[#fff] relative text-left">
            
            improve engagement and
          </span>
          <span className="font-['Helvetica_Neue'] text-[22px] font-medium leading-[44px] text-[#fff] relative text-left">
            to
          </span>
          <span className="font-['Helvetica_Neue'] text-[22px] font-medium leading-[44px] text-[#fff] relative text-left">
            drive growth.
            <br />
          </span>
          <span className="font-['Helvetica_Neue'] text-[22px] font-medium leading-[44px] text-[#fff] relative text-left">
            <br />
          </span>
          <span className="font-['Helvetica_Neue'] text-[22px] font-bold leading-[44px] text-[#fff] relative text-left">
            Improving retention:
          </span>
          <span className="font-['Helvetica_Neue'] text-[22px] font-medium leading-[44px] text-[#fff] relative text-left">
            
            Users may return to Spotify because of social experiences, such as
            interacting with friends through features they feel comfortable
            with.
            <br />
          </span>
          <span className="font-['Helvetica_Neue'] text-[22px] font-bold leading-[44px] text-[#fff] relative text-left">
            Driving acquisition:
          </span>
          <span className="font-['Helvetica_Neue'] text-[22px] font-medium leading-[44px] text-[#fff] relative text-left">
            
            When new users are drawn to the platform by socially engaging
            features, it boosts growth. If these features appeal to a broader
            audience, they can convert potential users into active ones.
          </span>
        </div>
      </div>
      <div className='flex w-[91px] h-[21px] justify-center items-center bg-[#98c1d9] rounded-[6px] relative z-[317] mt-[68px] mr-0 mb-0 ml-[684px]'>
        <span className="flex w-[91px] h-[21px] justify-center items-center shrink-0 font-['Inter'] text-[12px] font-normal leading-[21px] text-[#fff] relative text-center whitespace-nowrap z-[317]">
          Total Users
        </span>
      </div>
      <div className='w-[904px] h-[658.126px] relative z-[331] mt-[-0.13px] mr-0 mb-0 ml-[267px]'>
        <div className='w-[228.567px] h-[82.409px] bg-[url(./assets/images/b9e3f9c4-cf7c-4fa7-9df8-eeb16bf9fdde.png)] bg-cover bg-no-repeat absolute top-0 left-[227.596px] z-[322]' />
        <div className='w-[229.646px] h-[82.409px] bg-[url(./assets/images/39d71322-1f3a-4216-8d5f-4558168b06d6.png)] bg-cover bg-no-repeat absolute top-0 left-[462px] z-[331]' />
        <div className='w-[436px] h-[592px] bg-[url(./assets/images/b8582b00-9964-4c8a-8c1a-48553d3a4289.png)] bg-cover bg-no-repeat absolute top-[64.126px] left-0 z-[309]'>
          <div className='flex w-[194px] h-[23px] justify-between items-center relative z-[264] mt-[17px] mr-0 mb-0 ml-[81px]'>
            <span className="flex w-[106px] h-[21px] justify-center items-center shrink-0 font-['Helvetica_Neue'] text-[10px] font-bold leading-[21px] text-[#fff] relative text-center z-[264]">
              40 Users312 Total Selections
            </span>
            <div className='w-[82px] h-[21px] shrink-0 bg-[#00d14c] rounded-[6px] relative z-[188]'>
              <span className="flex justify-center items-center font-['Helvetica_Neue'] text-[12px] font-medium leading-[14.652px] text-[#fff] absolute top-0 bottom-0 left-0 right-0 text-center whitespace-nowrap z-[189]">
                Spotify
              </span>
            </div>
          </div>
          <div className='w-[115px] h-[56.36px] relative z-[325] mt-[-1.842px] mr-0 mb-0 ml-[174px]'>
            <div className='w-[69.324px] h-[57.36px] bg-[url(./assets/images/a8ce41a1-5907-4ef3-9707-4ea0d9be33b3.png)] bg-cover bg-no-repeat absolute top-0 left-[-7.364px] z-[320]' />
            <div className='w-[62.982px] h-[57.36px] bg-[url(./assets/images/7eca1c35-8cfd-4568-83fe-30795dcce3a6.png)] bg-cover bg-no-repeat absolute top-0 left-[59.382px] z-[325]' />
          </div>
          <div className='w-[431px] h-[22px] relative z-[262] mt-[0.48px] mr-0 mb-0 ml-[6px]'>
            <div className='bg-[#80b677] rounded-[6px] absolute top-0 bottom-px left-[106px] right-[216px] z-[198]'>
              <span className="flex justify-center items-center font-['Helvetica_Neue'] text-[12px] font-medium leading-[14.652px] text-[#fff] absolute top-0 bottom-0 left-0 right-0 text-center whitespace-nowrap z-[199]">
                Non-Intrusive
              </span>
            </div>
            <div className='bg-[#ffa8a8] rounded-[6px] absolute top-0 bottom-px left-[229px] right-[106px] z-[191]'>
              <span className="flex justify-center items-center font-['Helvetica_Neue'] text-[12px] font-medium leading-[14.652px] text-[#fff] absolute top-0 bottom-0 left-0 right-0 text-center whitespace-nowrap z-[192]">
                Intrusive
              </span>
            </div>
            <span className="flex w-[106px] h-[21px] justify-center items-center font-['Helvetica_Neue'] text-[10px] font-bold leading-[12.21px] text-[#fff] absolute top-px left-0 text-center z-[260]">
              224 <br />
              Total Selections
            </span>
            <span className="flex w-[106px] h-[21px] justify-center items-center font-['Helvetica_Neue'] text-[10px] font-bold leading-[12.21px] text-[#fff] absolute top-px left-[325px] text-center z-[262]">
              88 <br />
              Total Selections
            </span>
          </div>
          <div className='w-[97.25%] h-[14.02%] absolute top-[19.76%] left-[1.83%] z-[394]'>
            <div className='w-[19px] h-[65.364px] bg-[url(./assets/images/83852c99-3734-4e52-bbd2-1d6fa22f363e.png)] bg-cover bg-no-repeat absolute top-0 left-[166px] z-[337]' />
            <div className='w-[19px] h-[65.364px] bg-[url(./assets/images/6ad364df-3df2-4661-94ce-32ba5bec1a4d.png)] bg-cover bg-no-repeat absolute top-0 left-[249px] z-[394]' />
            <div className='w-[82px] h-[55px] bg-[#121212] absolute top-[23px] left-[76px] z-[194]'>
              <div className='bg-[#f15bb5] rounded-[6px] absolute top-0 bottom-0 left-0 right-0 z-[195]'>
                <span className="flex w-[82px] justify-center items-center font-['Helvetica_Neue'] text-[10px] font-medium leading-[12.21px] text-[#fff] absolute top-0 bottom-0 left-0 right-0 text-center z-[196]">
                  Passive easier discovery of friends' public playlists.
                </span>
              </div>
            </div>
            <div className='w-[19.34%] h-[66.27%] bg-[url(./assets/images/3b0f069b-dd94-4024-8d3b-636bcd94c0dd.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[33.73%] left-[64.86%] z-[226]'>
              <span className="flex w-[82px] h-full justify-center items-center font-['Helvetica_Neue'] text-[10px] font-medium leading-[12.21px] text-[#fff] absolute top-0 left-0 text-center z-[227]">
                In-app messaging/DMs.
              </span>
            </div>
            <span className="flex w-[72px] h-[20px] justify-center items-center font-['Helvetica_Neue'] text-[10px] font-bold leading-[12.21px] text-[#fff] absolute top-[41px] left-0 text-center z-[266]">
              64
              <br />
              Selections
            </span>
            <span className="flex w-[72px] h-[20px] justify-center items-center font-['Helvetica_Neue'] text-[10px] font-bold leading-[12.21px] text-[#fff] absolute top-[47px] left-[352px] text-center z-[272]">
              32 <br />
              Selections
            </span>
          </div>
          <div className='absolute top-[169.5px] bottom-[186.5px] left-[8px] right-[4px] z-[403]'>
            <div className='w-[20px] h-[84.364px] bg-[url(./assets/images/1be09de6-3dba-48c3-a9b6-3299dbc7a067.png)] bg-cover bg-no-repeat absolute top-0 left-[165px] z-[340]' />
            <div className='w-[20px] h-[84.364px] bg-[url(./assets/images/348db804-9be2-4b0c-a77d-7b567ba1d0f0.png)] bg-cover bg-no-repeat absolute top-0 left-[249px] z-[397]' />
            <span className="flex w-[72px] h-[20px] justify-center items-center font-['Helvetica_Neue'] text-[10px] font-bold leading-[12.21px] text-[#fff] absolute top-[64.5px] left-[352px] text-center z-[274]">
              19 <br />
              Selections
            </span>
            <div className='w-[19px] h-[92.364px] bg-[url(./assets/images/29e64ef5-782f-45eb-b915-1af2149eced3.png)] bg-cover bg-no-repeat absolute top-[72px] left-[166px] z-[343]' />
            <div className='w-[19px] h-[92.364px] bg-[url(./assets/images/9860731a-1209-4639-bd9f-8eb933e138ef.png)] bg-cover bg-no-repeat absolute top-[72px] left-[249px] z-[400]' />
            <div className='bg-[#9b5de5] rounded-[6px] absolute top-[118.5px] bottom-[62.5px] left-[72px] right-[266px] z-[201]'>
              <span className="flex w-[86px] justify-center items-center font-['Helvetica_Neue'] text-[10px] font-medium leading-[12.21px] text-[#fff] absolute top-0 bottom-0 left-0 right-0 text-center z-[202]">
                <br />
                Display your music taste and artists for friends. <br />
              </span>
            </div>
            <div className='w-[19.34%] h-[23.31%] bg-[#2ec4b6] rounded-[6px] absolute top-[51.48%] left-[64.86%] z-[229]'>
              <span className="flex w-[82px] h-full justify-center items-center font-['Helvetica_Neue'] text-[10px] font-medium leading-[12.21px] text-[#fff] absolute top-0 left-0 text-center z-[230]">
                Interactive story-like features.
                <br />
              </span>
            </div>
            <span className="flex w-[72px] h-[20px] justify-center items-center font-['Helvetica_Neue'] text-[10px] font-bold leading-[12.21px] text-[#fff] absolute top-[128.5px] left-0 text-center z-[268]">
              42 <br />
              Selections
            </span>
            <span className="flex w-[72px] h-[20px] justify-center items-center font-['Helvetica_Neue'] text-[10px] font-bold leading-[12.21px] text-[#fff] absolute top-[134.5px] left-[352px] text-center z-[273]">
              15 <br />
              Selections
            </span>
            <div className='w-[20px] h-[90.364px] bg-[url(./assets/images/6c693c33-2a6a-41ed-92bf-df2154f66280.png)] bg-cover bg-no-repeat absolute top-[153px] left-[165px] z-[346]' />
            <div className='w-[20px] h-[90.364px] bg-[url(./assets/images/12f54f5a-183e-459b-98d7-99ab37eb6263.png)] bg-cover bg-no-repeat absolute top-[153px] left-[249px] z-[403]' />
            <span className="flex w-[72px] h-[20px] justify-center items-center font-['Helvetica_Neue'] text-[10px] font-bold leading-[12.21px] text-[#fff] absolute top-[207.5px] left-0 text-center z-[269]">
              22
              <br />
              Selections
            </span>
            <span className="flex w-[72px] h-[20px] justify-center items-center font-['Helvetica_Neue'] text-[10px] font-bold leading-[12.21px] text-[#fff] absolute top-[213.5px] left-[352px] text-center z-[275]">
              
              11
              <br />
              Selections
            </span>
          </div>
          <div className='flex justify-between items-center absolute top-[213px] bottom-[319px] left-[8px] right-[71px] z-[267]'>
            <div className='w-[158px] h-[60px] shrink-0 relative z-[267]'>
              <div className='bg-[#00bbf9] rounded-[6px] absolute top-0 bottom-[5px] left-[76px] right-0 z-[204]'>
                <span className="flex w-[82px] justify-center items-center font-['Helvetica_Neue'] text-[10px] font-medium leading-[12.21px] text-[#fff] absolute top-0 bottom-0 left-0 right-0 text-center z-[205]">
                  User discovery features.
                </span>
              </div>
              <span className="flex w-[72px] h-[20px] justify-center items-center font-['Helvetica_Neue'] text-[10px] font-bold leading-[12.21px] text-[#fff] absolute top-[15px] left-0 text-center z-[267]">
                52
                <br />
                Selections
              </span>
            </div>
            <div className='w-[82px] h-[55px] shrink-0 bg-[#ffa500] rounded-[6px] relative z-[235]'>
              <span className="flex w-[82px] h-full justify-center items-center font-['Helvetica_Neue'] text-[10px] font-medium leading-[12.21px] text-[#fff] absolute top-0 left-0 text-center z-[236]">
                Live collaborative rooms for playlist editing.
              </span>
            </div>
          </div>
          <div className='absolute top-[364px] bottom-[117.5px] left-[84px] right-[71px] z-[406]'>
            <div className='bg-[#3d5a80] rounded-[6px] absolute top-0 bottom-[55.5px] left-0 right-[199px] z-[217]'>
              <span className="flex w-[82px] justify-center items-center font-['Helvetica_Neue'] text-[10px] font-medium leading-[12.21px] text-[#fff] absolute top-0 bottom-0 left-0 right-0 text-center z-[218]">
                Setting an activity status.
              </span>
            </div>
            <div className='w-[29.18%] h-[49.77%] bg-[#ee6c4d] rounded-[6px] absolute top-[5.43%] left-[70.82%] z-[238]'>
              <span className="flex w-[82px] h-full justify-center items-center font-['Helvetica_Neue'] text-[10px] font-medium leading-[12.21px] text-[#fff] absolute top-0 left-0 text-center z-[239]">
                Song comments and replies.
              </span>
            </div>
            <div className='w-[20px] h-[86.364px] bg-[url(./assets/images/40152979-9054-417a-b747-be74f4a9f77c.png)] bg-cover bg-no-repeat absolute top-[31.5px] left-[89px] z-[349]' />
            <div className='w-[20px] h-[86.364px] bg-[url(./assets/images/f989b532-6025-4828-b448-a7c96f8c8ccf.png)] bg-cover bg-no-repeat absolute top-[31.5px] left-[173px] z-[406]' />
          </div>
          <div className='absolute top-[440px] bottom-[45px] left-[8px] right-[4px] z-[391]'>
            <div className='bg-[#98c1d9] rounded-[6px] absolute top-0 bottom-[52px] left-[76px] right-[266px] z-[220]'>
              <span className="flex w-[82px] justify-center items-center font-['Helvetica_Neue'] text-[10px] font-medium leading-[12.21px] text-[#fff] absolute top-0 bottom-0 left-0 right-0 text-center z-[221]">
                Sharing live listening statistics.
              </span>
            </div>
            <div className='w-[19.34%] h-[51.4%] bg-[#693668] rounded-[6px] absolute top-0 left-[64.86%] z-[241]'>
              <span className="flex w-[82px] h-full justify-center items-center font-['Helvetica_Neue'] text-[10px] font-medium leading-[12.21px] text-[#fff] absolute top-0 left-0 text-center z-[242]">
                Tiktok style feeds or timelines.
              </span>
            </div>
            <span className="flex w-[72px] h-[20px] justify-center items-center font-['Helvetica_Neue'] text-[10px] font-bold leading-[12.21px] text-[#fff] absolute top-[14px] left-0 text-center z-[270]">
              15
              <br />
              Selections
            </span>
            <span className="flex w-[72px] h-[20px] justify-center items-center font-['Helvetica_Neue'] text-[10px] font-bold leading-[12.21px] text-[#fff] absolute top-[20px] left-[352px] text-center z-[276]">
              6 <br />
              Selections
            </span>
            <div className='w-[20px] h-[86.364px] bg-[url(./assets/images/679686fe-099f-4c00-9898-09c6af17bb25.png)] bg-cover bg-no-repeat absolute top-[28px] left-[165px] z-[352]' />
            <div className='w-[20px] h-[77.364px] bg-[url(./assets/images/14dc3c84-0dd4-4a22-9f0c-35304282cec8.png)] bg-cover bg-no-repeat absolute top-[33px] left-[249px] z-[391]' />
          </div>
          <div className='absolute top-[513px] bottom-[21px] left-[8px] right-[4px] z-[290]'>
            <div className='flex justify-between items-center absolute top-0 bottom-0 left-0 right-[265px] z-[271]'>
              <span className="flex w-[72px] h-[20px] justify-center items-center shrink-0 font-['Helvetica_Neue'] text-[10px] font-bold leading-[20px] text-[#fff] relative text-center z-[271]">
                29
                <br />
                Selections
              </span>
              <div className='w-[82px] h-[55px] shrink-0 bg-[#18848b] rounded-[6px] relative z-[223]'>
                <span className="flex w-[82px] justify-center items-center font-['Helvetica_Neue'] text-[10px] font-medium leading-[12.21px] text-[#fff] absolute top-0 bottom-0 left-0 right-0 text-center z-[224]">
                  Other
                </span>
              </div>
            </div>
            <div className='bg-[#44188b] rounded-[6px] absolute top-[3px] bottom-0 left-[275px] right-[67px] z-[290]'>
              <span className="flex w-[82px] justify-center items-center font-['Helvetica_Neue'] text-[10px] font-bold leading-[12.21px] text-[#fff] absolute top-0 bottom-0 left-0 right-0 text-center z-[291]">
                Other
              </span>
            </div>
            <span className="flex w-[72px] h-[20px] justify-center items-center font-['Helvetica_Neue'] text-[10px] font-bold leading-[12.21px] text-[#fff] absolute top-[20px] left-[352px] text-center z-[277]">
              5 <br />
              Selections
            </span>
          </div>
        </div>
        <div className='w-[437.5px] h-[594px] bg-[url(./assets/images/6152c608-2ab7-48dd-a568-dc2b870ad8e0.png)] bg-cover bg-no-repeat absolute top-[64.126px] left-[463.5px] z-[308]'>
          <div className='flex w-[204.791px] h-[22.283px] justify-between items-center relative z-[265] mt-[16px] mr-0 mb-0 ml-[174.709px]'>
            <div className='w-[96px] h-[21px] shrink-0 bg-[#3d5a80] rounded-[6px] relative z-[208]'>
              <span className="flex justify-center items-center font-['Helvetica_Neue'] text-[12px] font-medium leading-[14.652px] text-[#fff] absolute top-0 bottom-0 left-0 right-0 text-center whitespace-nowrap z-[209]">
                Non-Spotify
              </span>
            </div>
            <span className="flex w-[106px] h-[21px] justify-center items-center shrink-0 font-['Helvetica_Neue'] text-[10px] font-bold leading-[21px] text-[#fff] relative text-center z-[265]">
              25 Users197 Total Selections
            </span>
          </div>
          <div className='w-[122px] h-[57px] relative z-[355] mt-[-0.28px] mr-0 mb-0 ml-[173.5px]'>
            <div className='w-[55.364px] h-[58px] bg-[url(./assets/images/8425bca2-759f-4e93-8b94-d4297051a744.png)] bg-cover bg-no-repeat absolute top-0 left-[-7.364px] z-[329]' />
            <div className='w-[83.66px] h-[57.36px] bg-[url(./assets/images/ca97a98c-b9e9-434c-9fc5-19212e246719.png)] bg-cover bg-no-repeat absolute top-[0.16px] left-[45.703px] z-[355]' />
          </div>
          <div className='w-[408px] h-[22px] relative z-[263] mt-0 mr-0 mb-0 ml-[29.5px]'>
            <span className="flex w-[106px] h-[21px] justify-center items-center font-['Helvetica_Neue'] text-[10px] font-bold leading-[12.21px] text-[#fff] absolute top-0 left-0 text-center z-[261]">
              144
              <br />
              Total Selections
            </span>
            <span className="flex w-[106px] h-[21px] justify-center items-center font-['Helvetica_Neue'] text-[10px] font-bold leading-[12.21px] text-[#fff] absolute top-0 left-[302px] text-center z-[263]">
              53 <br />
              Total Selections
            </span>
            <div className='bg-[#81b778] rounded-[6px] absolute top-[0.52px] bottom-[0.48px] left-[97.314px] right-[201.686px] z-[211]'>
              <span className="flex justify-center items-center font-['Helvetica_Neue'] text-[12px] font-medium leading-[14.652px] text-[#fff] absolute top-0 bottom-0 left-0 right-0 text-center whitespace-nowrap z-[212]">
                Non-Intrusive
              </span>
            </div>
            <div className='bg-[#ffa8a8] rounded-[6px] absolute top-px bottom-0 left-[217px] right-[95px] z-[214]'>
              <span className="flex justify-center items-center font-['Helvetica_Neue'] text-[12px] font-medium leading-[14.652px] text-[#fff] absolute top-0 bottom-0 left-0 right-0 text-center whitespace-nowrap z-[215]">
                Intrusive
              </span>
            </div>
          </div>
          <div className='absolute top-[117.5px] bottom-[391px] left-[24.5px] right-[65px] z-[373]'>
            <div className='w-[19px] h-[65.364px] bg-[url(./assets/images/cd94f3a8-ea44-4211-8181-3965bc74de20.png)] bg-cover bg-no-repeat absolute top-0 left-[159px] z-[358]' />
            <div className='w-[19px] h-[65.364px] bg-[url(./assets/images/5f89e353-cbda-4b6d-b46e-534f18b46a45.png)] bg-cover bg-no-repeat absolute top-[0.5px] left-[237.895px] z-[373]' />
            <div className='bg-[#ffa500] rounded-[6px] absolute top-[28.5px] bottom-[2px] left-[264px] right-0 z-[246]'>
              <span className="flex w-[82px] justify-center items-center font-['Helvetica_Neue'] text-[10px] font-medium leading-[12.21px] text-[#fff] absolute top-0 bottom-0 left-[1.895px] right-[0.1px] text-center z-[247]">
                Live collaborative rooms for playlist editing.
              </span>
            </div>
            <span className="flex w-[82px] justify-center items-center font-['Helvetica_Neue'] text-[10px] font-medium leading-[12.21px] text-[#fff] absolute top-[29.5px] bottom-px left-[75px] right-[191px] text-center z-[253]">
              Passive easier discovery of friends' public playlists.
            </span>
            <div className='bg-[#f15bb5] rounded-[6px] absolute top-[30.5px] bottom-0 left-[72px] right-[194px] z-[252]' />
            <span className="flex w-[72px] h-[20px] justify-center items-center font-['Helvetica_Neue'] text-[10px] font-bold leading-[12.21px] text-[#fff] absolute top-[45.5px] left-0 text-center z-[298]">
              42
              <br />
              Selections
            </span>
          </div>
          <div className='absolute top-[170px] bottom-[266.5px] left-[21.5px] right-0 z-[379]'>
            <div className='w-[20px] h-[84.364px] bg-[url(./assets/images/629d1947-8ef1-4786-bf8d-91992b841bb0.png)] bg-cover bg-no-repeat absolute top-0 left-[161px] z-[361]' />
            <div className='w-[20px] h-[84.364px] bg-[url(./assets/images/4efb0968-f76d-4c7c-8fb3-078a9202a09a.png)] bg-cover bg-no-repeat absolute top-[0.5px] left-[240.895px] z-[376]' />
            <div className='bg-[#693668] rounded-[6px] absolute top-[49px] bottom-[53.5px] left-[268.895px] right-[65.105px] z-[248]'>
              <span className="flex w-[82px] justify-center items-center font-['Helvetica_Neue'] text-[10px] font-medium leading-[12.21px] text-[#fff] absolute top-0 bottom-0 left-0 right-0 text-center z-[249]">
                Tiktok style feeds or timelines.
              </span>
            </div>
            <div className='bg-[#9b5de5] rounded-[6px] absolute top-[51px] bottom-[51.5px] left-[75px] right-[255px] z-[256]'>
              <span className="flex w-[82px] justify-center items-center font-['Helvetica_Neue'] text-[10px] font-medium leading-[12.21px] text-[#fff] absolute top-[-1px] bottom-px left-[3px] right-px text-center z-[257]">
                Display your music taste and artists for friends.
              </span>
            </div>
            <span className="flex w-[72px] h-[20px] justify-center items-center font-['Helvetica_Neue'] text-[10px] font-bold leading-[12.21px] text-[#fff] absolute top-[64px] left-[344px] text-center z-[280]">
              12
              <br />
              Selections
            </span>
            <span className="flex w-[72px] h-[20px] justify-center items-center font-['Helvetica_Neue'] text-[10px] font-bold leading-[12.21px] text-[#fff] absolute top-[65px] left-0 text-center z-[297]">
              31
              <br />
              Selections
            </span>
            <div className='w-[19px] h-[92.364px] bg-[url(./assets/images/ca3b08e3-a5e1-471d-a694-40dd93622396.png)] bg-cover bg-no-repeat absolute top-[72px] left-[162px] z-[364]' />
            <div className='w-[19px] h-[92.364px] bg-[url(./assets/images/cdd0e402-9668-472e-ac2a-ae2ba9cab37e.png)] bg-cover bg-no-repeat absolute top-[72.5px] left-[240.895px] z-[379]' />
            <span className="flex w-[72px] h-[20px] justify-center items-center font-['Helvetica_Neue'] text-[10px] font-bold leading-[12.21px] text-[#fff] absolute top-[135px] left-[3px] text-center z-[296]">
              26
              <br />
              Selections
            </span>
          </div>
          <div className='absolute top-[295px] bottom-[187.5px] left-[24.5px] right-[65.105px] z-[382]'>
            <div className='bg-[#00bbf9] rounded-[6px] absolute top-0 bottom-[56.5px] left-[75px] right-[190.895px] z-[254]'>
              <span className="flex w-[82px] justify-center items-center font-['Helvetica_Neue'] text-[10px] font-medium leading-[12.21px] text-[#fff] absolute top-0 bottom-0 left-0 right-0 text-center z-[255]">
                <br />
                User discovery features.
                <br />
              </span>
            </div>
            <div className='w-[23.57%] h-[49.33%] bg-[#2ec4b6] rounded-[6px] absolute top-0 left-[76.43%] z-[232]'>
              <span className="flex w-[82px] h-full justify-center items-center font-['Helvetica_Neue'] text-[10px] font-medium leading-[12.21px] text-[#fff] absolute top-0 left-0 text-center z-[233]">
                <br />
                Interactive story-like features.
                <br />
              </span>
            </div>
            <div className='w-[20px] h-[90.364px] bg-[url(./assets/images/86961ae9-2b88-4632-8a1b-daef260f8ef4.png)] bg-cover bg-no-repeat absolute top-[28px] left-[158px] z-[367]' />
            <div className='w-[20px] h-[90.364px] bg-[url(./assets/images/3210d3b1-41f1-4ffd-b945-98aabb86ff21.png)] bg-cover bg-no-repeat absolute top-[28.5px] left-[237.895px] z-[382]' />
            <span className="flex w-[72px] h-[20px] justify-center items-center font-['Helvetica_Neue'] text-[10px] font-bold leading-[12.21px] text-[#fff] absolute top-[89px] left-0 text-center z-[295]">
              19
              <br />
              Selections
            </span>
          </div>
          <div className='flex justify-between items-center absolute top-[372px] bottom-[167px] left-[99.5px] right-[65px] z-[258]'>
            <div className='w-[82px] h-[55px] shrink-0 bg-[#98c1d9] rounded-[6px] relative z-[258]'>
              <span className="flex w-[82px] justify-center items-center font-['Helvetica_Neue'] text-[10px] font-medium leading-[12.21px] text-[#fff] absolute top-0 bottom-0 left-0 right-0 text-center z-[259]">
                Sharing live listening statistics.
              </span>
            </div>
            <div className='w-[82px] h-[55px] shrink-0 bg-[#3d84a8] rounded-[6px] relative z-[250]'>
              <span className="flex w-[82px] justify-center items-center font-['Helvetica_Neue'] text-[10px] font-medium leading-[12.21px] text-[#fff] absolute top-[-1px] bottom-px left-[-0.1px] right-[0.1px] text-center z-[251]">
                In-app messaging/DMs.
              </span>
            </div>
          </div>
          <div className='absolute top-[396.5px] bottom-[46px] left-[24.5px] right-[65.105px] z-[388]'>
            <div className='w-[20px] h-[86.364px] bg-[url(./assets/images/a56017aa-6727-42fb-97c8-8237f3000e7a.png)] bg-cover bg-no-repeat absolute top-0 left-[237.895px] z-[385]' />
            <div className='w-[20px] h-[86.364px] bg-[url(./assets/images/38cb73d4-a54c-409d-8965-9f3452fa1a1d.png)] bg-cover bg-no-repeat absolute top-[3.5px] left-[158px] z-[334]' />
            <div className='bg-[#d99898] rounded-[6px] absolute top-[50.5px] bottom-[46px] left-[77px] right-[188.895px] z-[312]'>
              <span className="flex w-[82px] justify-center items-center font-['Helvetica_Neue'] text-[10px] font-medium leading-[12.21px] text-[#fff] absolute top-0 bottom-0 left-0 right-0 text-center z-[313]">
                <br />
                Optional social sharing of listening activity.
                <br />
              </span>
            </div>
            <div className='w-[23.57%] h-[36.3%] bg-[#ee6c4d] rounded-[6px] absolute top-[33.33%] left-[76.43%] z-[244]'>
              <span className="flex w-[82px] h-full justify-center items-center font-['Helvetica_Neue'] text-[10px] font-medium leading-[12.21px] text-[#fff] absolute top-0 left-0 text-center z-[245]">
                Song comments and replies.
              </span>
            </div>
            <span className="flex w-[72px] h-[20px] justify-center items-center font-['Helvetica_Neue'] text-[10px] font-bold leading-[12.21px] text-[#fff] absolute top-[66.5px] left-0 text-center z-[294]">
              15
              <br />
              Selections
            </span>
            <div className='w-[19px] h-[86.364px] bg-[url(./assets/images/1753609b-ac8f-4ce3-8767-bd5a31ff901b.png)] bg-cover bg-no-repeat absolute top-[72.5px] left-[159px] z-[370]' />
            <div className='w-[20px] h-[81.364px] bg-[url(./assets/images/a53633a4-6ba0-4697-bfe0-87a34386ff40.png)] bg-cover bg-no-repeat absolute top-[76.5px] left-[238px] z-[388]' />
          </div>
          <div className='flex justify-between items-center absolute top-[516px] bottom-[19px] left-[24.5px] right-[65.105px] z-[292]'>
            <div className='flex w-[159px] h-[59px] justify-between items-center shrink-0 relative z-[292]'>
              <span className="flex w-[72px] h-[20px] justify-center items-center shrink-0 font-['Helvetica_Neue'] text-[10px] font-bold leading-[20px] text-[#fff] relative text-center z-[292]">
                11
                <br />
                Selections
              </span>
              <div className='w-[82px] h-[55px] shrink-0 bg-[#18848b] rounded-[6px] relative z-[284]'>
                <span className="flex w-[82px] justify-center items-center font-['Helvetica_Neue'] text-[10px] font-bold leading-[12.21px] text-[#fff] absolute top-0 bottom-0 left-0 right-0 text-center z-[285]">
                  Other
                </span>
              </div>
            </div>
            <div className='w-[82px] h-[55px] shrink-0 bg-[#44188b] rounded-[6px] relative z-[287]'>
              <span className="flex w-[82px] justify-center items-center font-['Helvetica_Neue'] text-[10px] font-bold leading-[12.21px] text-[#fff] absolute top-0 bottom-0 left-0 right-0 text-center z-[288]">
                Other
              </span>
            </div>
          </div>
        </div>
        <span className="flex w-[72px] h-[20px] justify-center items-center font-['Helvetica_Neue'] text-[10px] font-bold leading-[12.21px] text-[#fff] absolute top-[228.126px] left-[832px] text-center z-[278]">
          19 <br />
          Selections
        </span>
        <span className="flex w-[72px] h-[20px] justify-center items-center font-['Helvetica_Neue'] text-[10px] font-bold leading-[12.21px] text-[#fff] absolute top-[368.126px] left-[832px] text-center z-[279]">
          9<br />
          Selections
        </span>
        <span className="flex w-[72px] h-[20px] justify-center items-center font-['Helvetica_Neue'] text-[10px] font-bold leading-[12.21px] text-[#fff] absolute top-[447.126px] left-[832px] text-center z-[281]">
          
          7<br />
          Selections
        </span>
        <span className="flex w-[72px] h-[20px] justify-center items-center font-['Helvetica_Neue'] text-[10px] font-bold leading-[12.21px] text-[#fff] absolute top-[523.126px] left-[832px] text-center z-[282]">
          4<br />
          Selections
        </span>
        <span className="flex w-[72px] h-[20px] justify-center items-center font-['Helvetica_Neue'] text-[10px] font-bold leading-[12.21px] text-[#fff] absolute top-[600.126px] left-[832px] text-center z-[293]">
          2<br />
          Selections
        </span>
      </div>
      <div className='w-[650px] h-[100px] relative z-[307] mt-0 mr-0 mb-0 ml-[485px]'>
        <div className='w-[20px] h-[86.364px] bg-[url(./assets/images/4ff6696e-223c-4d9b-803d-a1129f5056ca.png)] bg-cover bg-no-repeat absolute top-0 left-[-1px] z-[302]' />
        <div className='w-[20px] h-[86.364px] bg-[url(./assets/images/3f0e906c-3dfd-4da7-8c71-0fb9c6191b34.png)] bg-cover bg-no-repeat absolute top-0 left-[487px] z-[305]' />
        <span className="flex w-[131px] h-[42px] justify-center items-center font-['Helvetica_Neue'] text-[23px] font-bold leading-[28.083px] text-[#fff] absolute top-[58px] left-[18px] text-center whitespace-nowrap z-[306]">
          Retention
        </span>
        <span className="flex w-[131px] h-[42px] justify-center items-center font-['Helvetica_Neue'] text-[23px] font-bold leading-[28.083px] text-[#fff] absolute top-[58px] left-[519px] text-center whitespace-nowrap z-[307]">
          Acquisition
        </span>
      </div>
      <div className='w-[1000px] h-[646.584px] relative z-[407] mt-[93px] mr-0 mb-0 ml-[219px]'>
        <span className="flex h-[83px] justify-start items-start font-['Helvetica'] text-[40px] font-bold leading-[80px] text-[#fff] absolute top-0 left-[calc(50%-500px)] text-left whitespace-nowrap z-[90]">
          Personas
        </span>
        <span className="flex h-[83px] justify-start items-start font-['Helvetica'] text-[40px] font-bold leading-[80px] text-[#fff] absolute top-0 left-[calc(50%-500px)] text-left whitespace-nowrap z-[179]">
          Personas
        </span>
        <div className='flex w-[650px] h-[646.584px] pt-[17.769px] pr-[17.769px] pb-[17.769px] pl-[17.769px] gap-[10.306px] items-start flex-nowrap bg-[#252525] rounded-[11.372px] absolute top-0 left-[350px] overflow-hidden z-[105]'>
          <div className='flex w-[222.116px] flex-col gap-[11.372px] items-center shrink-0 flex-nowrap relative z-[106]'>
            <div className='flex w-[219.984px] pt-[17.059px] pr-0 pb-[17.059px] pl-0 flex-col gap-[12.794px] items-center shrink-0 flex-nowrap bg-[#1d1a1a] rounded-[3.554px] relative overflow-hidden z-[107]'>
              <span className="flex w-[104px] h-[23px] justify-center items-start shrink-0 basis-auto font-['Manrope'] text-[17.058502197265625px] font-extrabold leading-[23px] text-[#ece7f6] relative text-center whitespace-nowrap z-[108]">
                Emily Carter
              </span>
              <div className='w-[145.353px] h-[145.353px] shrink-0 bg-[url(./assets/images/5a2033cb-2cf6-4051-ad3b-0b47b6439459.png)] bg-cover bg-no-repeat rounded-[50%] relative z-[109]' />
              <div className='flex w-[189.776px] flex-col gap-[5.686px] items-start shrink-0 flex-nowrap relative z-[110]'>
                <div className='flex w-[189.776px] gap-[2.843px] items-start shrink-0 flex-nowrap relative z-[111]'>
                  <span className="flex w-[68.589px] h-[15.637px] justify-start items-center shrink-0 basis-auto font-['Manrope'] text-[8.529251098632812px] font-semibold leading-[15.637px] text-[#a2a2a2] relative text-left uppercase whitespace-nowrap z-[112]">
                    Age
                  </span>
                  <span className="flex w-[118.343px] h-[16px] justify-start items-start shrink-0 basis-auto font-['Manrope'] text-[11.372334480285645px] font-medium leading-[15.535px] text-[#eaebf5] relative text-left whitespace-nowrap z-[113]">
                    26
                  </span>
                </div>
                <div className='flex w-[189.776px] gap-[2.843px] items-start shrink-0 flex-nowrap relative z-[114]'>
                  <span className="flex w-[68.589px] h-[15.637px] justify-start items-center shrink-0 basis-auto font-['Manrope'] text-[8.529251098632812px] font-semibold leading-[15.637px] text-[#a2a2a2] relative text-left uppercase whitespace-nowrap z-[115]">
                    Education
                  </span>
                  <span className="flex w-[118.343px] h-[32px] justify-start items-start shrink-0 font-['Manrope'] text-[11.372334480285645px] font-medium leading-[15.535px] text-[#eaebf5] relative text-left z-[116]">
                    Bachelors in Marketing
                  </span>
                </div>
                <div className='flex w-[189.776px] gap-[2.843px] items-start shrink-0 flex-nowrap relative z-[117]'>
                  <span className="flex w-[68.589px] h-[15.637px] justify-start items-center shrink-0 basis-auto font-['Manrope'] text-[8.529251098632812px] font-semibold leading-[15.637px] text-[#a2a2a2] relative text-left uppercase whitespace-nowrap z-[118]">
                    Status
                  </span>
                  <span className="flex w-[118.343px] h-[16px] justify-start items-start shrink-0 basis-auto font-['Manrope'] text-[11.372334480285645px] font-medium leading-[15.535px] text-[#eaebf5] relative text-left whitespace-nowrap z-[119]">
                    Single
                  </span>
                </div>
                <div className='flex w-[189.776px] gap-[2.843px] items-start shrink-0 flex-nowrap relative z-[120]'>
                  <span className="flex w-[68.589px] h-[15.637px] justify-start items-center shrink-0 basis-auto font-['Manrope'] text-[8.529251098632812px] font-semibold leading-[15.637px] text-[#a2a2a2] relative text-left uppercase whitespace-nowrap z-[121]">
                    Occupation
                  </span>
                  <span className="flex w-[118.343px] h-[32px] justify-start items-start shrink-0 font-['Manrope'] text-[11.372334480285645px] font-medium leading-[15.535px] text-[#eaebf5] relative text-left z-[122]">
                    Digital Marketing Strategist
                  </span>
                </div>
                <div className='flex w-[189.776px] gap-[2.843px] items-start shrink-0 flex-nowrap relative z-[123]'>
                  <span className="flex w-[68.589px] h-[15.637px] justify-start items-center shrink-0 basis-auto font-['Manrope'] text-[8.529251098632812px] font-semibold leading-[15.637px] text-[#a2a2a2] relative text-left uppercase whitespace-nowrap z-[124]">
                    Location
                  </span>
                  <span className="flex w-[118.343px] h-[16px] justify-start items-start shrink-0 basis-auto font-['Manrope'] text-[11.372334480285645px] font-medium leading-[15.535px] text-[#eaebf5] relative text-left whitespace-nowrap z-[125]">
                    USA
                  </span>
                </div>
              </div>
            </div>
            <div className='flex w-[219.984px] pt-[11.372px] pr-[11.372px] pb-[11.372px] pl-[11.372px] gap-[8.529px] items-start shrink-0 flex-nowrap bg-[#1d1a1a] rounded-[5.331px] relative overflow-hidden z-[126]'>
              <div className='w-[13.505px] h-[11.728px] shrink-0 bg-[url(./assets/images/b3c34e18-1bbe-49b8-9178-43845444def5.png)] bg-cover bg-no-repeat relative z-[127]' />
              <span className="flex w-[175.205px] h-[102px] justify-start items-start shrink-0 font-['Manrope'] text-[12.083105087280273px] font-medium leading-[16.506px] text-[#eaebf5] relative text-left z-[128]">
                “I love sharing music, but I want to do it on my own terms.
                Features that let me stay connected without forcing constant
                interactions are ideal.”
              </span>
            </div>
            <div className='flex w-[220.694px] pt-[11.372px] pr-[11.372px] pb-[11.372px] pl-[11.372px] flex-col gap-[8.529px] items-start shrink-0 flex-nowrap bg-[#1d1a1a] rounded-[5.331px] relative overflow-hidden z-[129]'>
              <span className="flex w-[197.95px] h-[17px] justify-start items-start shrink-0 basis-auto font-['Manrope'] text-[12.793876647949219px] font-extrabold leading-[17px] text-[#9e9e9e] relative text-left whitespace-nowrap z-[130]">
                Personality
              </span>
              <div className='flex pt-[1.422px] pr-[1.422px] pb-[1.422px] pl-[1.422px] gap-[4.265px] items-center self-stretch shrink-0 flex-wrap relative z-[131]'>
                <div className='flex w-[65.059px] pt-[2.843px] pr-[8.529px] pb-[2.843px] pl-[8.529px] gap-[3.554px] items-center flex-nowrap bg-[#9b85f3] rounded-[19.902px] relative z-[136]'>
                  <span className="h-[17px] shrink-0 basis-auto font-['Manrope'] text-[12.083105087280273px] font-medium leading-[16.506px] text-[#fff] relative text-left whitespace-nowrap z-[137]">
                    Spender
                  </span>
                </div>
                <div className='flex w-[70.059px] pt-[2.843px] pr-[8.529px] pb-[2.843px] pl-[8.529px] gap-[3.554px] items-center flex-nowrap bg-[#5b91c3] rounded-[19.902px] relative z-[132]'>
                  <span className="h-[17px] shrink-0 basis-auto font-['Manrope'] text-[12.083105087280273px] font-medium leading-[16.506px] text-[#fff] relative text-left whitespace-nowrap z-[133]">
                    Extrovert
                  </span>
                </div>
                <div className='flex w-[76.059px] pt-[2.843px] pr-[8.529px] pb-[2.843px] pl-[8.529px] gap-[3.554px] items-center flex-nowrap bg-[#ed78d3] rounded-[19.902px] relative z-[138]'>
                  <span className="h-[17px] shrink-0 basis-auto font-['Manrope'] text-[12.083105087280273px] font-medium leading-[16.506px] text-[#fff] relative text-left whitespace-nowrap z-[139]">
                    Tech-savy
                  </span>
                </div>
                <div className='flex w-[58.059px] pt-[2.843px] pr-[8.529px] pb-[2.843px] pl-[8.529px] gap-[3.554px] items-center flex-nowrap bg-[#dc6262] rounded-[19.902px] relative z-[134]'>
                  <span className="h-[17px] shrink-0 basis-auto font-['Manrope'] text-[12.083105087280273px] font-medium leading-[16.506px] text-[#fff] relative text-left whitespace-nowrap z-[135]">
                    Reader
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className='flex w-[382.039px] flex-col gap-[11.372px] items-center shrink-0 flex-nowrap relative z-[140]'>
            <div className='flex w-[382.039px] pt-[11.372px] pr-[11.372px] pb-[11.372px] pl-[11.372px] flex-col gap-[8.529px] items-start shrink-0 flex-nowrap bg-[#1d1a1a] rounded-[5.331px] relative overflow-hidden z-[141]'>
              <span className="h-[17px] shrink-0 basis-auto font-['Manrope'] text-[12.793876647949219px] font-extrabold leading-[17px] text-[#9e9e9e] relative text-left whitespace-nowrap z-[142]">
                Bio
              </span>
              <span className="flex w-[344.368px] h-[112px] justify-start items-start shrink-0 font-['Manrope'] text-[11.372334480285645px] font-medium leading-[15.535px] text-[#eaebf5] relative text-left z-[143]">
                Emily is an avid Spotify user who listens to music and podcasts
                during her workday, commute, and gym sessions. As a tech-savvy
                professional, she values seamless user experiences, especially
                when it comes to staying connected with her friends. However,
                Emily is cautious about her privacy and prefers to control how
                her social interactions on platforms like Spotify are managed.
              </span>
            </div>
            <div className='flex pt-[11.372px] pr-[11.372px] pb-[11.372px] pl-[11.372px] flex-col gap-[8.529px] items-start self-stretch shrink-0 flex-nowrap bg-[#1d1a1a] rounded-[5.331px] relative overflow-hidden z-[144]'>
              <span className="h-[17px] shrink-0 basis-auto font-['Manrope'] text-[12.793876647949219px] font-extrabold leading-[17px] text-[#9e9e9e] relative text-left whitespace-nowrap z-[145]">
                Core needs
              </span>
              <div className='flex w-[360.055px] flex-col gap-[5.686px] items-center shrink-0 flex-nowrap relative z-[146]'>
                <div className='flex w-[360.055px] gap-[5.686px] items-start shrink-0 flex-nowrap relative z-[147]'>
                  <span className="h-[17px] shrink-0 basis-auto font-['Manrope'] text-[12.793876647949219px] font-extrabold leading-[17px] text-[#fdfdfd] relative text-left whitespace-nowrap z-[148]">
                    •
                  </span>
                  <div className="w-[344.368px] shrink-0 font-['Manrope'] text-[11.372334480285645px] font-medium leading-[15.535px] relative text-left z-[149]">
                    <span className="font-['Manrope'] text-[11.372334480285645px] font-bold leading-[15.535px] text-[#eaebf5] relative text-left">
                      Balanced social interaction:
                    </span>
                    <span className="font-['Manrope'] text-[11.372334480285645px] font-medium leading-[15.535px] text-[#eaebf5] relative text-left">
                      Emily wants features that let her
                    </span>
                    <span className="font-['Manrope'] text-[11.372334480285645px] font-bold leading-[15.535px] text-[#eaebf5] relative text-left">
                      share music and experiences
                    </span>
                    <span className="font-['Manrope'] text-[11.372334480285645px] font-medium leading-[15.535px] text-[#eaebf5] relative text-left">
                      
                      with friends without feeling too exposed or pressured.
                    </span>
                  </div>
                </div>
                <div className='flex w-[360.055px] gap-[5.686px] items-center shrink-0 flex-nowrap relative z-[150]'>
                  <span className="h-[17px] shrink-0 basis-auto font-['Manrope'] text-[12.793876647949219px] font-extrabold leading-[17px] text-[#fdfdfd] relative text-left whitespace-nowrap z-[151]">
                    •
                  </span>
                  <div className="w-[344.368px] shrink-0 font-['Manrope'] text-[11.372334480285645px] font-medium leading-[15.535px] relative text-left z-[152]">
                    <span className="font-['Manrope'] text-[11.372334480285645px] font-bold leading-[15.535px] text-[#eaebf5] relative text-left">
                      Customization:
                    </span>
                    <span className="font-['Manrope'] text-[11.372334480285645px] font-medium leading-[15.535px] text-[#eaebf5] relative text-left">
                      
                      She needs the ability to customize her social visibility
                      on Spotify, such as controlling who can see her listening
                      habits whilst still maintaining a social presence.
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className='flex pt-[11.372px] pr-[11.372px] pb-[11.372px] pl-[11.372px] flex-col gap-[8.529px] items-start self-stretch shrink-0 flex-nowrap bg-[#1d1a1a] rounded-[5.331px] relative overflow-hidden z-[153]'>
              <span className="h-[17px] shrink-0 basis-auto font-['Manrope'] text-[12.793876647949219px] font-extrabold leading-[17px] text-[#9e9e9e] relative text-left whitespace-nowrap z-[154]">
                Frustrations
              </span>
              <div className='flex w-[360.055px] flex-col gap-[4.265px] items-center shrink-0 flex-nowrap relative z-[155]'>
                <div className='flex w-[360.055px] gap-[5.686px] items-center shrink-0 flex-nowrap relative z-[156]'>
                  <span className="h-[17px] shrink-0 basis-auto font-['Manrope'] text-[12.793876647949219px] font-extrabold leading-[17px] text-[#fdfdfd] relative text-left whitespace-nowrap z-[157]">
                    •
                  </span>
                  <div className="w-[344.368px] shrink-0 font-['Manrope'] text-[11.372334480285645px] font-medium leading-[15.535px] relative text-left z-[158]">
                    <span className="font-['Manrope'] text-[11.372334480285645px] font-bold leading-[15.535px] text-[#eaebf5] relative text-left">
                      Overwhelming social pressure:
                    </span>
                    <span className="font-['Manrope'] text-[11.372334480285645px] font-medium leading-[15.535px] text-[#eaebf5] relative text-left">
                      
                      Emily dislikes intrusive features that make her feel
                      obligated to interact, like real-time listening or public
                      social sharing, which can interfere with her focus.
                      <br />
                    </span>
                  </div>
                </div>
                <div className='flex w-[360.055px] gap-[5.686px] items-start shrink-0 flex-nowrap relative z-[159]'>
                  <span className="h-[17px] shrink-0 basis-auto font-['Manrope'] text-[12.793876647949219px] font-extrabold leading-[17px] text-[#fdfdfd] relative text-left whitespace-nowrap z-[160]">
                    •
                  </span>
                  <div className="w-[344.368px] shrink-0 font-['Manrope'] text-[11.372334480285645px] font-medium leading-[15.535px] relative text-left z-[161]">
                    <span className="font-['Manrope'] text-[11.372334480285645px] font-bold leading-[15.535px] text-[#eaebf5] relative text-left">
                      Limited user control:
                    </span>
                    <span className="font-['Manrope'] text-[11.372334480285645px] font-medium leading-[15.535px] text-[#eaebf5] relative text-left">
                      
                      She feels that there isn’t enough control over her social
                      engagement settings, leading to occasional discomfort in
                      how her music activity is shared publicly.
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className='flex gap-[11.372px] items-center self-stretch shrink-0 flex-nowrap relative z-[162]'>
              <div className='flex pt-[11.372px] pr-[11.372px] pb-[11.372px] pl-[11.372px] flex-col gap-[8.529px] items-start grow shrink-0 basis-0 flex-nowrap bg-[#1d1a1a] rounded-[5.331px] relative overflow-hidden z-[163]'>
                <span className="h-[17px] shrink-0 basis-auto font-['Manrope'] text-[12.793876647949219px] font-extrabold leading-[17px] text-[#9e9e9e] relative text-left whitespace-nowrap z-[164]">
                  Payment medium
                </span>
                <div className='flex gap-[8.529px] items-center self-stretch shrink-0 flex-wrap relative z-[165]'>
                  <div className='w-[63px] h-[45.762px] relative z-[166]'>
                    <div className='w-[39.092px] h-[33.762px] bg-[url(./assets/images/4c1f9f50-f5c2-44d6-ab55-6e9e8562d5eb.png)] bg-cover bg-no-repeat relative z-[167] mt-0 mr-0 mb-0 ml-[11.728px]' />
                    <span className="block h-[12px] font-['Manrope'] text-[8.529251098632812px] font-medium leading-[11.651px] text-[#eaebf5] relative text-left whitespace-nowrap z-[168] mt-0 mr-0 mb-0 ml-0">
                      Digital Payment
                    </span>
                  </div>
                </div>
              </div>
              <div className='flex pt-[11.372px] pr-[11.372px] pb-[11.372px] pl-[11.372px] flex-col gap-[8.529px] items-start grow shrink-0 basis-0 flex-nowrap bg-[#1d1a1a] rounded-[5.331px] relative overflow-hidden z-[169]'>
                <span className="h-[17px] shrink-0 basis-auto font-['Manrope'] text-[12.793876647949219px] font-extrabold leading-[17px] text-[#9e9e9e] relative text-left whitespace-nowrap z-[170]">
                  Platform
                </span>
                <div className='flex gap-[8.529px] items-center self-stretch shrink-0 flex-wrap relative z-[171]'>
                  <div className='w-[39.092px] h-[45.762px] relative z-[172]'>
                    <div className='flex w-[39.092px] h-[45.762px] flex-col items-center flex-nowrap absolute top-0 left-0 z-[173]'>
                      <div className='w-[39.092px] h-[33.762px] shrink-0 bg-[url(./assets/images/cc2a55cdb3811d62b6df68a9e9c5ea4ba9aab030.png)] bg-cover bg-no-repeat relative z-[174]' />
                      <span className="h-[12px] shrink-0 basis-auto font-['Manrope'] text-[8.529251098632812px] font-medium leading-[11.651px] text-[#eaebf5] relative text-left whitespace-nowrap z-[175]">
                        Desktop
                      </span>
                    </div>
                  </div>
                  <div className='w-[45px] h-[45.762px] relative z-[176]'>
                    <div className='w-[39.092px] h-[33.762px] bg-[url(./assets/images/39642d35-a87e-4feb-a8fc-23d1602e5956.png)] bg-cover bg-no-repeat relative z-[177] mt-0 mr-0 mb-0 ml-[2.665px]' />
                    <span className="block h-[12px] font-['Manrope'] text-[8.529251098632812px] font-medium leading-[11.651px] text-[#eaebf5] relative text-left whitespace-nowrap z-[178] mt-0 mr-0 mb-0 ml-0">
                      Mobile App
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='w-[146px] h-[146px] bg-[url(./assets/images/c7aef326-bb0b-4777-9ceb-a1c92f041577.png)] bg-cover bg-no-repeat rounded-[500px] absolute top-[70px] left-[406px] z-[91]' />
        <span className="flex w-[321px] h-[520px] justify-start items-start font-['Helvetica_Neue'] text-[22px] font-medium leading-[44px] text-[#fff] absolute top-[84px] left-[calc(50%-498px)] text-left z-[181]">
          Based on extensive user interviews, I've developed a detailed persona
          to better understand our target audience. This persona serves as a
          tool to empathize with users, helping us identify their pain points
          and needs. It will guide our ideation process and inform the
          development of tailored solutions for our key customer segment.
        </span>
        <span className="flex w-[321px] h-[520px] justify-start items-start font-['Helvetica_Neue'] text-[22px] font-medium leading-[44px] text-[#fff] absolute top-[84px] left-[calc(50%-498px)] text-left z-[407]">
          Based on extensive user interviews, I've developed a detailed persona
          to better understand our target audience. This persona serves as a
          tool to empathize with users, helping us identify their pain points
          and needs. It will guide our ideation process and inform the
          development of tailored solutions for our key customer segment.
        </span>
      </div>
      <div className='w-[1037px] h-[198px] relative z-[594] mt-[88.416px] mr-0 mb-0 ml-[219px]'>
        <span className="flex h-[83px] justify-start items-start font-['Helvetica'] text-[40px] font-bold leading-[80px] text-[#fff] absolute top-0 left-[calc(50%-518.5px)] text-left overflow-hidden whitespace-nowrap z-[93]">
          Customer Journey Map <br />
        </span>
        <span className="flex w-[1035px] h-[127px] justify-start items-start font-['Helvetica_Neue'] text-[22px] font-medium leading-[44px] text-[#fff] absolute top-[71px] left-[calc(50%-516.5px)] text-left z-[594]">
          A customer journey map outlines the user’s experience as they interact
          with a product, helping to uncover what they are truly seeking at each
          stage. By breaking down their actions, thoughts, and emotions, it
          reveals pain points and unmet needs. The goal is to understand the
          user's motivations and frustrations, guiding improvements that align
          with their expectations.
        </span>
      </div>
      <div className='w-[1047px] h-[861.157px] text-[0px] bg-[#252525] relative overflow-hidden z-[408] mt-[74px] mr-0 mb-0 ml-[222px]'>
        <span className="block h-[21px] font-['Roboto'] text-[17.948570251464844px] font-medium leading-[21px] text-[#ff9b9b] tracking-[0.72px] relative text-left capitalize whitespace-nowrap z-[409] mt-[31.911px] mr-0 mb-0 ml-[63.817px]">
          Customer Journey - Spotify Social Features
        </span>
        <div className='w-[919.366px] h-[75.782px] relative z-[593] mt-[13.896px] mr-0 mb-0 ml-[63.817px]'>
          <div className='w-[299.143px] h-[73.789px] text-[0px] rounded-[7.977px] absolute top-0 left-[620.223px] z-[473]'>
            <span className="block h-[13px] font-['Roboto'] text-[10.968571662902832px] font-medium leading-[12.854px] text-[#fbd165] tracking-[0.44px] relative text-left uppercase whitespace-nowrap z-[475] mt-[9.974px] mr-0 mb-0 ml-[32.408px]">
              expectations
            </span>
            <span className="flex w-[265.24px] h-[38px] justify-start items-start font-['Roboto'] text-[9.971427917480469px] font-normal leading-[12.464px] text-[#fff] tracking-[0.4px] relative text-left overflow-hidden z-[476] mt-[3.574px] mr-0 mb-0 ml-[27.048px]">
              Showcase her music preferences on her profile. <br />
              Easily create and share playlists inspired by others.
              <br />
              Engage with friends changing music taste.
            </span>
            <div className='w-[299.143px] h-[73.789px] bg-[rgba(191,191,191,0.16)] rounded-[7.977px] absolute top-0 left-0 z-[474]' />
          </div>
          <div className='w-[299.143px] h-[73.789px] text-[0px] rounded-[7.977px] absolute top-[0.5px] left-[310.112px] z-[477]'>
            <span className="block h-[13px] font-['Roboto'] text-[10.968571662902832px] font-medium leading-[12.854px] text-[#a4a4f5] tracking-[0.44px] relative text-left uppercase whitespace-nowrap z-[479] mt-[9.969px] mr-0 mb-0 ml-[27.92px]">
              scenario
            </span>
            <span className="flex w-[214.386px] h-[38px] justify-start items-start font-['Roboto'] text-[9.971427917480469px] font-normal leading-[12.464px] text-[#fff] tracking-[0.4px] relative text-left z-[480] mt-[3.451px] mr-0 mb-0 ml-[26.923px]">
              Emily wants to create a couple playlists with the inspiration of
              her friends playlists and share it such that everyone can view it!
            </span>
            <div className='w-[299.143px] h-[73.789px] bg-[rgba(191,191,191,0.16)] rounded-[7.977px] absolute top-0 left-0 z-[478]' />
          </div>
          <div className='w-[299.143px] h-[73.789px] absolute top-[1.994px] left-0 z-[410]'>
            <div className='w-[299.143px] h-[73.789px] bg-[rgba(191,191,191,0.16)] rounded-[7.977px] absolute top-0 left-0 z-[411]' />
            <div className='w-[59.829px] h-[59.829px] absolute top-[6.983px] left-[15.954px] z-[414]'>
              <div className='w-full h-full bg-[#a28576] rounded-[89.743px] absolute top-0 left-0 overflow-hidden z-[415]'>
                <div className='w-[79.022px] h-[87.576px] relative z-[416] mt-[5.519px] mr-0 mb-0 ml-[-17.949px]'>
                  <div className='w-[27.29%] h-[22.62%] bg-[url(./assets/images/6edb3b4c-aca9-4edb-97ed-4b9668f9115c.png)] bg-[length:100%_100%] bg-no-repeat absolute top-0 left-[46.98%] z-[433]' />
                  <div className='w-[82.93%] h-[67.85%] bg-[url(./assets/images/366a469e-e1df-4907-a606-a186e83febd4.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[4.58%] left-[17.07%] z-[417]' />
                  <div className='w-[23.61%] h-[32.39%] bg-[url(./assets/images/60831324-7066-4746-84ca-bf52d34fc6a9.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[4.78%] left-[47.43%] z-[427]' />
                  <div className='w-[16.17%] h-[2.03%] absolute top-[17%] left-[46.31%] z-[434]'>
                    <div className='w-[35.52%] h-full bg-[url(./assets/images/cd8a83c8-8eaa-4943-bd56-739cbe480eec.png)] bg-[length:100%_100%] bg-no-repeat absolute top-0 left-[64.48%] z-[435]' />
                    <div className='w-[33.05%] h-[79.8%] bg-[url(./assets/images/70e1db25-a079-4f7c-8151-067a7a4b1384.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[9.47%] left-0 z-[436]' />
                  </div>
                  <div className='w-[3.62%] h-[6.82%] bg-[url(./assets/images/263a7ac3-a37a-4a1d-9e63-e352c9beb86e.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[20.31%] left-[51.6%] z-[432]' />
                  <div className='w-[10.24%] h-[2.24%] absolute top-[21.17%] left-[50.24%] z-[428]'>
                    <div className='w-[1.391px] h-[1.391px] bg-[url(./assets/images/16a17dd0-0a7a-45e4-bd02-e162c304413a.png)] bg-[length:100%_100%] bg-no-repeat relative z-[430] mt-0 mr-0 mb-0 ml-0' />
                    <div className='w-[1.391px] h-[1.391px] bg-[url(./assets/images/3ba7fe40-9c6e-41ce-9986-ce53bb881656.png)] bg-[length:100%_100%] bg-no-repeat relative z-[429] mt-[-0.82px] mr-0 mb-0 ml-[6.701px]' />
                  </div>
                  <div className='w-[7.44%] h-[6.74%] bg-[url(./assets/images/80b1ba82-d0ba-412a-8a92-285cc33ce867.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[22.69%] left-[65.99%] z-[437]' />
                  <div className='w-[15.07%] h-[33.61%] bg-[url(./assets/images/67df4d4e-4505-4a95-9be7-afeed4162078.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[26.79%] left-[8.53%] z-[438]' />
                  <div className='w-[47.03%] h-[72.75%] bg-[url(./assets/images/d1da1009-1d90-45ed-8119-0c572cf7c5b9.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[27.22%] left-[36.85%] z-[418]' />
                  <div className='w-[6.49%] h-[2.05%] bg-[url(./assets/images/fdd0feb6-d535-45d7-ad44-0edcba875096.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[30.33%] left-[52.13%] z-[431]' />
                  <div className='w-[16.03%] h-[5.7%] bg-[url(./assets/images/5cf48abb-42c5-445e-a92b-d8137931cec5.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[42.59%] left-[54.88%] z-[426]' />
                  <div className='w-[4.45%] h-[0.49%] bg-[url(./assets/images/abb4bcde-24dc-47f0-bc44-3228d4b18718.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[44.19%] left-[74.74%] z-[463]' />
                  <div className='w-[46.25%] h-[48.42%] bg-[url(./assets/images/b6d82084-a10a-4b13-886c-122712649c14.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[44.2%] left-[52.39%] z-[425]' />
                  <div className='w-[98.58%] h-[55.78%] bg-[url(./assets/images/a2e979d9-7a54-419b-ad63-2498ece7c516.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[44.22%] left-[0.07%] z-[472]' />
                  <div className='w-[47.25%] h-[55.7%] bg-[url(./assets/images/c63bb98f-f098-422d-be33-0f4da131d5f1.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[44.27%] left-[36.93%] z-[423]' />
                  <div className='w-[4.33%] h-[2.38%] bg-[url(./assets/images/165462b0-31c7-49a9-951e-ffa8ef7ab311.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[47.74%] left-[67.31%] z-[456]' />
                  <div className='w-[6.11%] h-[6.69%] absolute top-[47.83%] left-[5.46%] z-[419]'>
                    <div className='w-full h-full bg-[url(./assets/images/f79940b0-1d55-4b38-ae44-b28b11b8be4b.png)] bg-[length:100%_100%] bg-no-repeat absolute top-0 left-0 z-[420]' />
                    <div className='w-full h-full absolute top-0 left-0 z-[421]'>
                      <div className='w-full h-full bg-[url(./assets/images/dd40b157-0529-45b9-9d40-d4eca0d422c0.png)] bg-[length:100%_100%] bg-no-repeat absolute top-0 left-0 z-[422]' />
                    </div>
                  </div>
                  <div className='w-[47.46%] h-[49.33%] bg-[url(./assets/images/1c0fda70-8dde-469b-86ba-d606b60f651e.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[47.95%] left-0 z-[439]' />
                  <div className='w-[4.03%] h-[2.85%] bg-[url(./assets/images/80740cf4-4fc7-4633-b7ea-8ca6572ca93f.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[49.12%] left-[84.73%] z-[450]' />
                  <div className='w-[4.75%] h-[1.06%] bg-[url(./assets/images/8bb3343e-52b9-402b-9809-a0e881887153.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[53.29%] left-[42.42%] z-[446]' />
                  <div className='w-[4.77%] h-[1.03%] bg-[url(./assets/images/fe818fd0-4119-414a-9706-8eefb7db1e9f.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[53.46%] left-[76.4%] z-[462]' />
                  <div className='w-[4.27%] h-[2.55%] bg-[url(./assets/images/171f8961-02da-421a-adab-f2feddddb5a9.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[54.96%] left-[64.56%] z-[471]' />
                  <div className='w-[2.77%] h-[1.78%] bg-[url(./assets/images/cbc76f71-eab9-4ad7-b6c1-2bf30e1cfa44.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[58.49%] left-[89.36%] z-[455]' />
                  <div className='w-[4.33%] h-[2.4%] bg-[url(./assets/images/5cd14ca3-e49d-4b1b-906e-daa119705153.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[59.51%] left-[54.78%] z-[454]' />
                  <div className='w-[2.21%] h-[4.07%] bg-[url(./assets/images/f3cf9976-cd36-41ca-b3a6-7fd5d8cf0548.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[62.75%] left-[40.05%] z-[452]' />
                  <div className='w-[4.51%] h-[2.07%] bg-[url(./assets/images/278ef21a-58fe-4069-9fd2-a66aff0948fa.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[62.75%] left-[69.04%] z-[470]' />
                  <div className='w-[4.03%] h-[2.85%] bg-[url(./assets/images/20ae6b26-1713-44b6-af76-3e8461aad37c.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[63.39%] left-[83.13%] z-[451]' />
                  <div className='w-[3.63%] h-[3.26%] bg-[url(./assets/images/1a1a33c6-ac85-4916-9c7b-2f332f671eda.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[66.4%] left-[6.86%] z-[444]' />
                  <div className='w-[3.63%] h-[3.26%] bg-[url(./assets/images/e822abf5-00d7-4ba0-bf57-f89a94415efe.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[66.42%] left-[48.94%] z-[440]' />
                  <div className='w-[4.77%] h-[1.03%] bg-[url(./assets/images/dbb670e9-d36f-4366-914d-433eee011fa7.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[67.66%] left-[59.81%] z-[460]' />
                  <div className='w-[4.77%] h-[1.03%] bg-[url(./assets/images/ad5029a6-8c6b-4a77-8753-bb91e35d8297.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[70.02%] left-[75.04%] z-[461]' />
                  <div className='w-[4.03%] h-[2.85%] bg-[url(./assets/images/e66f65c4-9f92-4cb4-b109-61694b8b9c19.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[71.26%] left-[2.38%] z-[448]' />
                  <div className='w-[17.49%] h-[11.24%] bg-[url(./assets/images/9b7c88b9-ac25-496c-99a2-a2786ec56033.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[71.57%] left-[51.84%] z-[424]' />
                  <div className='w-[3.63%] h-[3.26%] bg-[url(./assets/images/bf175a2b-dfcd-44bd-82bd-22e79e0cfb6e.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[71.69%] left-[92.86%] z-[445]' />
                  <div className='w-[1.54%] h-[3.06%] bg-[url(./assets/images/ba4ca890-544a-439a-97fd-524eb425b11e.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[72.33%] left-[13.87%] z-[453]' />
                  <div className='w-[1.7%] h-[4.22%] bg-[url(./assets/images/281554f2-6ec6-40e1-9067-f4fcfece3a6f.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[73.59%] left-[85.88%] z-[468]' />
                  <div className='w-[3.54%] h-[3.35%] bg-[url(./assets/images/9f1cfa8c-16c5-4c57-b2f9-0690173b84ed.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[74.46%] left-[68.17%] z-[469]' />
                  <div className='w-[3.63%] h-[3.26%] bg-[url(./assets/images/a7222a83-cde7-4939-b2c9-3746d4b0da30.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[74.67%] left-[35.96%] z-[443]' />
                  <div className='w-[1.54%] h-[2.53%] bg-[url(./assets/images/ae6a37f0-d718-4e43-aad2-8a3927a105ff.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[78.27%] left-[27.46%] z-[441]' />
                  <div className='w-[4.03%] h-[2.85%] bg-[url(./assets/images/b4929b8c-2192-4435-b6f1-643ac3865a08.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[81.39%] left-[8.06%] z-[449]' />
                  <div className='w-[4.33%] h-[2.35%] bg-[url(./assets/images/b7d6d677-114f-4ec1-af17-1ac7cf234f6f.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[81.6%] left-[39.68%] z-[457]' />
                  <div className='w-[4.33%] h-[2.4%] bg-[url(./assets/images/2b42bc6a-a379-40d1-bf1f-ffb380248530.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[81.62%] left-[17.15%] z-[458]' />
                  <div className='w-[4.77%] h-[1.03%] bg-[url(./assets/images/99f766ba-b57b-45d2-974b-3d5406c5a28f.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[82.84%] left-[80.53%] z-[465]' />
                  <div className='w-[4.77%] h-[1.03%] bg-[url(./assets/images/1baa306a-ae50-4631-a984-0a2c45ff49af.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[82.99%] left-[53.94%] z-[464]' />
                  <div className='w-[1.7%] h-[4.22%] bg-[url(./assets/images/7bd93e2a-c393-411c-863a-0825f8454b0f.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[83.94%] left-[91.99%] z-[467]' />
                  <div className='w-[2.68%] h-[3.87%] bg-[url(./assets/images/f978d041-1aca-43cb-8a5a-ce0035d3f24f.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[86.65%] left-[65.34%] z-[466]' />
                  <div className='w-[4.51%] h-[1.97%] bg-[url(./assets/images/12d4c213-2c80-49fa-b045-c1d8d76f7ae0.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[86.79%] left-[28.24%] z-[459]' />
                  <div className='w-[4.75%] h-[1.06%] bg-[url(./assets/images/55d2a5f9-cff2-4026-acca-8e57d81bc901.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[90.49%] left-[49.78%] z-[447]' />
                  <div className='w-[2.41%] h-[4%] bg-[url(./assets/images/fdc5fe24-38ff-4864-a624-b78feb2f91ec.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[90.64%] left-[19.83%] z-[442]' />
                </div>
              </div>
            </div>
            <span className="flex h-[13px] justify-start items-start font-['Roboto'] text-[10.968571662902832px] font-medium leading-[12.854px] text-[#4ebfd8] tracking-[0.44px] absolute top-[9.974px] left-[107.692px] text-left uppercase whitespace-nowrap z-[412]">
              user info
            </span>
            <span className="flex w-[188.46px] h-[50px] justify-start items-start font-['Roboto'] text-[9.971427917480469px] font-normal leading-[12.464px] text-[#fff] tracking-[0.4px] absolute top-[23.686px] left-[108.066px] text-left z-[413]">
              Emily, 26, is a Spotify user who enjoys creating playlists and
              sharing her music taste and connecting with others through music.
            </span>
          </div>
          <div className='w-[62px] h-[62px] bg-[url(./assets/images/115b5df4-4181-42c5-a70e-9711e572f60e.png)] bg-cover bg-no-repeat rounded-[500px] absolute top-[9.192px] left-[15.183px] z-[593]' />
        </div>
        <div className='w-[98.61%] h-[422.07%] absolute top-[18.41%] left-[6.1%] z-[592]'>
          <div className='flex w-[919.366px] h-[670.862px] gap-[15.954px] items-start flex-nowrap absolute top-0 left-0 z-[481]'>
            <div className='flex w-[91.737px] flex-col gap-[7.977px] items-start shrink-0 flex-nowrap relative z-[482]'>
              <div className='w-[91.737px] h-[23.931px] shrink-0 text-[0px] rounded-[3.989px] relative z-[483]'>
                <span className="block h-[12px] font-['Roboto'] text-[9.971427917480469px] font-bold leading-[11.685px] text-[#fff] relative text-left uppercase whitespace-nowrap z-[485] mt-[5.981px] mr-0 mb-0 ml-[24.655px]">
                  Stages
                </span>
                <div className='w-[91.737px] h-[23.931px] bg-[#bb0505] rounded-[3.989px] absolute top-0 left-0 z-[484]' />
              </div>
              <div className='flex w-[91.737px] h-[82.887px] pt-[31.41px] pr-[22.436px] pb-[31.41px] pl-[22.436px] gap-[3.989px] justify-center items-center shrink-0 flex-nowrap bg-[#c68dea] rounded-[3.989px] relative z-[486]'>
                <span className="h-[12px] shrink-0 basis-auto font-['Roboto'] text-[9.971427917480469px] font-semibold leading-[11.685px] text-[#fff] relative text-left uppercase whitespace-nowrap z-[487]">
                  Goals
                </span>
              </div>
              <div className='flex w-[91.613px] h-[174.5px] pt-[31.41px] pr-[22.436px] pb-[31.41px] pl-[22.436px] gap-[3.989px] justify-center items-center shrink-0 flex-nowrap bg-[#3bb5c8] rounded-[3.989px] relative z-[488]'>
                <span className="h-[12px] shrink-0 basis-auto font-['Roboto'] text-[9.971427917480469px] font-semibold leading-[11.685px] text-[#fff] relative text-left uppercase whitespace-nowrap z-[489]">
                  Actions
                </span>
              </div>
              <div className='flex w-[91.737px] h-[100.337px] pt-[31.41px] pr-[22.436px] pb-[31.41px] pl-[22.436px] gap-[3.989px] justify-center items-center shrink-0 flex-nowrap bg-[#9180fb] rounded-[3.989px] relative z-[490]'>
                <span className="h-[12px] shrink-0 basis-auto font-['Roboto'] text-[9.971427917480469px] font-semibold leading-[11.685px] text-[#fff] relative text-left uppercase whitespace-nowrap z-[491]">
                  Thoughts
                </span>
              </div>
              <div className='flex w-[91.737px] pt-[31.41px] pr-[22.436px] pb-[31.41px] pl-[22.436px] gap-[3.989px] justify-center items-center shrink-0 flex-nowrap bg-[#f7cb15] rounded-[3.989px] relative z-[492]'>
                <span className="h-[12px] shrink-0 basis-auto font-['Roboto'] text-[9.971427917480469px] font-semibold leading-[11.685px] text-[#fff] relative text-left uppercase whitespace-nowrap z-[493]">
                  Emotions
                </span>
              </div>
              <div className='flex w-[91.737px] h-[174.5px] pt-[31.41px] pr-[22.436px] pb-[31.41px] pl-[22.436px] gap-[3.989px] justify-center items-center shrink-0 flex-nowrap bg-[rgba(242,92,8,0.44)] rounded-[3.989px] relative z-[494]'>
                <span className="h-[12px] shrink-0 basis-auto font-['Roboto'] text-[9.971427917480469px] font-semibold leading-[11.685px] text-[#fff] relative text-left uppercase whitespace-nowrap z-[495]">
                  opportunities
                </span>
              </div>
            </div>
            <div className='flex w-[149.571px] flex-col gap-[7.977px] items-start shrink-0 flex-nowrap relative z-[496]'>
              <div className='w-[149.571px] h-[23.931px] shrink-0 relative z-[497]'>
                <div className='w-[149.571px] h-[23.931px] bg-[#1260a8] rounded-[3.989px] absolute top-0 left-0 z-[498]' />
                <div className='w-[14.957px] h-[14.957px] absolute top-[4.483px] left-[130.626px] overflow-hidden z-[500]'>
                  <div className='w-[14.957px] h-[14.957px] relative z-[501] mt-0 mr-0 mb-0 ml-0'>
                    <div className='w-full h-full bg-[url(./assets/images/16bd8ae5-325b-4af9-b4ca-2a26cb059fbc.png)] bg-[length:100%_100%] bg-no-repeat absolute top-0 left-0 z-[502]' />
                  </div>
                </div>
                <span className="flex h-[9px] justify-start items-start font-['Roboto'] text-[7.634375095367432px] font-bold leading-[8.947px] text-[#fff] absolute top-[7.229px] left-[2.118px] text-left uppercase whitespace-nowrap z-[499]">
                  stage 1: Discovery & Inspiration
                </span>
              </div>
              <div className='flex w-[149.197px] h-[82.887px] pt-[11.966px] pr-[7.977px] pb-[11.966px] pl-[7.977px] gap-[3.989px] justify-center items-center shrink-0 flex-nowrap bg-[rgba(198,141,234,0.4)] rounded-[3.989px] relative z-[503]'>
                <span className="flex w-[133.243px] h-[48px] justify-start items-start grow shrink-0 basis-0 font-['Roboto'] text-[9.971427917480469px] font-normal leading-[11.966px] text-[#fff] relative text-left z-[504]">
                  Emily wants to discover and get inspired by her friends'
                  playlists and music tastes to create her own playlist.
                </span>
              </div>
              <div className='flex w-[149.197px] h-[174.5px] pt-[11.966px] pr-[7.977px] pb-[11.966px] pl-[7.977px] gap-[3.989px] justify-center items-center shrink-0 flex-nowrap bg-[rgba(60,182,200,0.25)] rounded-[3.989px] relative z-[505]'>
                <span className="flex w-[133.243px] h-[148px] justify-start items-start grow shrink-0 basis-0 font-['Roboto'] text-[9.971427917480469px] font-normal leading-[11.966px] text-[#fff] relative text-left z-[506]">
                  She opens Spotify and searches for her friends’ profiles.
                  <br />
                  She navigates through the friends' playlists but struggles to
                  gather meaningful inspiration.
                  <br />
                  Emily tries to identify trends or genres from the playlists
                  but finds the profiles lack depth.
                </span>
              </div>
              <div className='flex w-[149.571px] h-[100.337px] pt-[11.966px] pr-[7.977px] pb-[11.966px] pl-[7.977px] gap-[3.989px] justify-center items-center shrink-0 flex-nowrap bg-[rgba(146,129,252,0.25)] rounded-[3.989px] relative z-[507]'>
                <span className="flex w-[133.617px] h-[24px] justify-start items-start grow shrink-0 basis-0 font-['Roboto'] text-[9.971427917480469px] font-normal leading-[11.966px] text-[#fff] relative text-left z-[508]">
                  “I hope I can easily find what music my friends are into!”
                </span>
              </div>
              <div className='flex w-[149.571px] h-[74.287px] pt-[11.966px] pr-[7.977px] pb-[11.966px] pl-[7.977px] gap-[3.989px] justify-center items-end shrink-0 flex-nowrap bg-[rgba(135,174,180,0.16)] rounded-[3.989px] relative z-[509]' />
              <div className='flex w-[149.571px] h-[174.5px] pt-[11.966px] pr-[7.977px] pb-[11.966px] pl-[7.977px] gap-[3.989px] justify-center items-center shrink-0 flex-nowrap bg-[rgba(242,97,8,0.25)] rounded-[3.989px] relative z-[510]'>
                <span className="flex w-[133.617px] h-[72px] justify-start items-start grow shrink-0 basis-0 font-['Roboto'] text-[9.971427917480469px] font-medium leading-[11.966px] text-[#fff] relative text-left z-[511]">
                  Introduce a clear way to users to showcase their music
                  preferences, favorite artists, and playlist themes on their
                  profiles.
                </span>
              </div>
            </div>
            <div className='flex w-[149.571px] flex-col gap-[7.977px] items-start shrink-0 flex-nowrap relative z-[512]'>
              <div className='w-[149.571px] h-[23.931px] shrink-0 relative z-[513]'>
                <div className='w-[149.571px] h-[23.931px] bg-[#f1abbc] rounded-[3.989px] absolute top-0 left-0 z-[514]' />
                <div className='w-[14.957px] h-[14.957px] absolute top-[4.483px] left-[130.626px] overflow-hidden z-[516]'>
                  <div className='w-[14.957px] h-[14.957px] relative z-[517] mt-0 mr-0 mb-0 ml-0'>
                    <div className='w-full h-full bg-[url(./assets/images/81aed3f2-56b1-48aa-a360-67c3b7dd0307.png)] bg-[length:100%_100%] bg-no-repeat absolute top-0 left-0 z-[518]' />
                  </div>
                </div>
                <span className="flex h-[9px] justify-start items-start font-['Roboto'] text-[7.634375095367432px] font-bold leading-[8.947px] text-[#fff] absolute top-[7.229px] left-[7.603px] text-left uppercase whitespace-nowrap z-[515]">
                  Stage 2: Playlist Creation
                </span>
              </div>
              <div className='flex w-[149.571px] h-[82.887px] pt-[11.966px] pr-[7.977px] pb-[11.966px] pl-[7.977px] gap-[3.989px] justify-center items-center shrink-0 flex-nowrap bg-[rgba(198,141,234,0.4)] rounded-[3.989px] relative z-[519]'>
                <span className="flex w-[133.617px] h-[48px] justify-start items-start grow shrink-0 basis-0 font-['Roboto'] text-[9.971427917480469px] font-normal leading-[11.966px] text-[#fff] relative text-left z-[520]">
                  Emily wants to start creating her playlist by pulling together
                  songs and ideas from her friends’ playlists.
                </span>
              </div>
              <div className='flex w-[149.571px] h-[174.5px] pt-[11.966px] pr-[7.977px] pb-[11.966px] pl-[7.977px] gap-[3.989px] justify-center items-center shrink-0 flex-nowrap bg-[#2b494e] rounded-[3.989px] relative z-[521]'>
                <span className="flex w-[133.617px] h-[160px] justify-start items-start grow shrink-0 basis-0 font-['Roboto'] text-[9.971427917480469px] font-normal leading-[11.966px] text-[#fff] relative text-left z-[522]">
                  She begins to build her playlist based on her mood and the
                  songs she found.
                  <br />
                  Emily struggles to find her friends’ playlists again without
                  easy access or filtering options.
                  <br />
                  She adds songs but wishes for more guidance on how to organize
                  or improve her playlist.
                </span>
              </div>
              <div className='flex w-[149.571px] h-[100.337px] pt-[11.966px] pr-[7.977px] pb-[11.966px] pl-[7.977px] gap-[3.989px] justify-center items-center shrink-0 flex-nowrap bg-[#403c5b] rounded-[3.989px] relative z-[523]'>
                <span className="flex w-[133.617px] h-[84px] justify-start items-start grow shrink-0 basis-0 font-['Roboto'] text-[9.971427917480469px] font-normal leading-[11.966px] text-[#fff] relative text-left z-[524]">
                  “Ugh I have to scroll through their entire playlists one by
                  one! I know what I want to create, but I’m spending too much
                  time finding the right songs.”
                  <br />
                </span>
              </div>
              <div className='flex w-[149.571px] h-[74.287px] pt-[11.966px] pr-[7.977px] pb-[11.966px] pl-[7.977px] gap-[3.989px] justify-center items-center shrink-0 flex-nowrap bg-[rgba(135,174,180,0.16)] rounded-[3.989px] relative z-[525]' />
              <div className='flex w-[149.571px] h-[174.5px] pt-[11.966px] pr-[7.977px] pb-[11.966px] pl-[7.977px] gap-[3.989px] justify-center items-center shrink-0 flex-nowrap bg-[rgba(242,97,8,0.25)] rounded-[3.989px] relative z-[526]'>
                <span className="flex w-[133.617px] h-[92px] justify-start items-start grow shrink-0 basis-0 font-['Roboto'] text-[9.971427917480469px] font-medium leading-[11.966px] text-[#fff] relative text-left z-[527]">
                  Provide suggestions or inspiration prompts based on users'
                  friends’ music habits or preferences.
                  <br />
                  Make it easier to browse through people’s profile so music
                  discovery is easier
                </span>
              </div>
            </div>
            <div className='flex w-[149.571px] flex-col gap-[7.977px] items-start shrink-0 flex-nowrap relative z-[528]'>
              <div className='w-[149.571px] h-[23.931px] shrink-0 relative z-[529]'>
                <div className='w-[149.571px] h-[23.931px] bg-[#f77315] rounded-[3.989px] absolute top-0 left-0 z-[530]' />
                <div className='w-[14.957px] h-[14.957px] absolute top-[4.483px] left-[130.626px] overflow-hidden z-[533]'>
                  <div className='w-[14.957px] h-[14.957px] relative z-[534] mt-0 mr-0 mb-0 ml-0'>
                    <div className='w-full h-full bg-[url(./assets/images/516023b8-392c-4381-9b6e-c61ccd85c080.png)] bg-[length:100%_100%] bg-no-repeat absolute top-0 left-0 z-[535]' />
                  </div>
                </div>
                <span className="flex h-[9px] justify-start items-start font-['Roboto'] text-[7.634375095367432px] font-bold leading-[8.947px] text-[#fff] absolute top-[7.229px] left-[7.853px] text-left uppercase whitespace-nowrap z-[531]">
                  Stage 3: Playlist Sharing
                </span>
                <span className="flex h-[9px] justify-start items-start font-['Roboto'] text-[7.634375095367432px] font-bold leading-[8.947px] text-[#fff] absolute top-[7.229px] left-[7.853px] text-left uppercase whitespace-nowrap z-[532]">
                  Stage 3: Playlist Sharing
                </span>
              </div>
              <div className='flex w-[149.571px] h-[82.887px] pt-[11.966px] pr-[7.977px] pb-[11.966px] pl-[7.977px] gap-[3.989px] justify-center items-center shrink-0 flex-nowrap bg-[#664f74] rounded-[3.989px] relative z-[536]'>
                <span className="flex w-[133.617px] h-[48px] justify-start items-start grow shrink-0 basis-0 font-['Roboto'] text-[9.971427917480469px] font-normal leading-[11.966px] text-[#fff] relative text-left z-[537]">
                  Emily wants to share her finished playlist with friends and
                  make it easy for them to find and enjoy.
                </span>
              </div>
              <div className='flex w-[149.197px] h-[174.5px] pt-[11.966px] pr-[7.977px] pb-[11.966px] pl-[7.977px] gap-[3.989px] justify-center items-center shrink-0 flex-nowrap bg-[#2b494e] rounded-[3.989px] relative z-[538]'>
                <span className="flex w-[133.243px] h-[160px] justify-start items-start grow shrink-0 basis-0 font-['Roboto'] text-[9.971427917480469px] font-normal leading-[11.966px] text-[#fff] relative text-left z-[539]">
                  She shares the playlist but realizes her friends might not
                  notice it due to Spotify’s cluttered interface.
                  <br />
                  She checks her profile and notices there’s no good way to
                  highlight or promote her playlist.
                  <br />
                  Emily feels unsure if her effort will even be seen or
                  appreciated by her friends.
                </span>
              </div>
              <div className='flex w-[149.571px] h-[100.337px] pt-[11.966px] pr-[7.977px] pb-[11.966px] pl-[7.977px] gap-[3.989px] justify-center items-center shrink-0 flex-nowrap bg-[#403c5b] rounded-[3.989px] relative z-[540]'>
                <span className="flex w-[133.617px] h-[72px] justify-start items-start grow shrink-0 basis-0 font-['Roboto'] text-[9.971427917480469px] font-normal leading-[11.966px] text-[#fff] relative text-left z-[541]">
                  “I’m proud of this playlist, but how can I make sure my
                  friends see it?”
                  <br />
                  <br />
                  “There’s no way to feature this or make it stand out!”
                </span>
              </div>
              <div className='flex w-[149.571px] h-[74.287px] pt-[11.966px] pr-[7.977px] pb-[11.966px] pl-[7.977px] gap-[3.989px] justify-center items-center shrink-0 flex-nowrap bg-[rgba(135,174,180,0.16)] rounded-[3.989px] relative z-[542]' />
              <div className='flex w-[149.571px] h-[174.5px] pt-[11.966px] pr-[7.977px] pb-[11.966px] pl-[7.977px] gap-[3.989px] justify-center items-center shrink-0 flex-nowrap bg-[rgba(242,97,8,0.25)] rounded-[3.989px] relative z-[543]'>
                <span className="flex w-[133.617px] h-[116px] justify-start items-start grow shrink-0 basis-0 font-['Roboto'] text-[9.971427917480469px] font-medium leading-[11.966px] text-[#fff] relative text-left z-[544]">
                  Allow users to feature certain playlists on their profiles
                  prominently.
                  <br />
                  Create profile sections for users to showcase playlists based
                  on genre, concert history, or themes, making them easier to
                  discover and interact with.
                </span>
              </div>
            </div>
            <div className='flex w-[149.571px] flex-col gap-[7.977px] items-start shrink-0 flex-nowrap relative z-[545]'>
              <div className='w-[149.571px] h-[23.931px] shrink-0 relative z-[546]'>
                <div className='w-[149.571px] h-[23.931px] bg-[url(./assets/images/2e15fefe-d7ea-405c-b215-9e994a80da8a.png)] bg-cover bg-no-repeat absolute top-0 left-0 z-[547]' />
                <div className='w-[14.957px] h-[14.957px] absolute top-[4.483px] left-[130.626px] overflow-hidden z-[550]'>
                  <div className='w-[14.957px] h-[14.957px] relative z-[551] mt-0 mr-0 mb-0 ml-0'>
                    <div className='w-full h-full bg-[url(./assets/images/02c35809-fa38-4ced-8a16-9b519e046281.png)] bg-[length:100%_100%] bg-no-repeat absolute top-0 left-0 z-[552]' />
                  </div>
                </div>
                <span className="flex h-[9px] justify-start items-start font-['Roboto'] text-[7.634375095367432px] font-bold leading-[8.947px] text-[#fff] absolute top-[7.229px] left-[6.357px] text-left uppercase whitespace-nowrap z-[548]">
                  Stage 4: Disillusionment
                </span>
                <span className="flex h-[9px] justify-start items-start font-['Roboto'] text-[7.634375095367432px] font-bold leading-[8.947px] text-[#fff] absolute top-[7.229px] left-[6.357px] text-left uppercase whitespace-nowrap z-[549]">
                  Stage 4: Disillusionment
                </span>
              </div>
              <div className='flex w-[149.571px] h-[82.887px] pt-[11.966px] pr-[7.977px] pb-[11.966px] pl-[7.977px] gap-[3.989px] justify-center items-center shrink-0 flex-nowrap bg-[#664f74] rounded-[3.989px] relative z-[553]'>
                <span className="flex w-[133.617px] h-[72px] justify-start items-start grow shrink-0 basis-0 font-['Roboto'] text-[9.971427917480469px] font-normal leading-[11.966px] text-[#fff] relative text-left z-[554]">
                  Emily wants to ensure that her playlist and music tastes are
                  visible and engaging, but she is starting to feel like Spotify
                  isn’t offering what she needs socially.
                </span>
              </div>
              <div className='flex w-[149.197px] h-[174.5px] pt-[11.966px] pr-[7.977px] pb-[11.966px] pl-[7.977px] gap-[3.989px] justify-center items-center shrink-0 flex-nowrap bg-[#2b494e] rounded-[3.989px] relative z-[555]'>
                <span className="flex w-[133.243px] h-[160px] justify-start items-start grow shrink-0 basis-0 font-['Roboto'] text-[9.971427917480469px] font-normal leading-[11.966px] text-[#fff] relative text-left z-[556]">
                  She looks at other platforms to see if they offer better
                  social features.
                  <br />
                  Emily contemplates if staying on Spotify is worth it given the
                  lack of meaningful interaction.
                  <br />
                  She considers moving to a platform with more creative and
                  personalized profile features.
                </span>
              </div>
              <div className='flex w-[149.571px] h-[100.337px] pt-[11.966px] pr-[7.977px] pb-[11.966px] pl-[7.977px] gap-[3.989px] justify-center items-center shrink-0 flex-nowrap bg-[#403c5b] rounded-[3.989px] relative z-[557]'>
                <span className="flex w-[133.617px] h-[84px] justify-start items-start grow shrink-0 basis-0 font-['Roboto'] text-[9.971427917480469px] font-normal leading-[11.966px] text-[#fff] relative text-left z-[558]">
                  “If no one can find my playlist, what’s the point of creating
                  it?”
                  <br />
                  “Maybe there’s another app that lets me showcase my music
                  better.”
                </span>
              </div>
              <div className='flex w-[149.571px] h-[74.287px] pt-[11.966px] pr-[7.977px] pb-[11.966px] pl-[7.977px] gap-[3.989px] justify-center items-center shrink-0 flex-nowrap bg-[rgba(135,174,180,0.16)] rounded-[3.989px] relative z-[559]' />
              <div className='flex w-[149.571px] h-[174.5px] pt-[11.966px] pr-[7.977px] pb-[11.966px] pl-[7.977px] gap-[3.989px] justify-center items-center shrink-0 flex-nowrap bg-[rgba(242,97,8,0.25)] rounded-[3.989px] relative z-[560]'>
                <span className="flex w-[133.617px] h-[164px] justify-start items-start grow shrink-0 basis-0 font-['Roboto'] text-[9.971427917480469px] font-medium leading-[11.966px] text-[#fff] relative text-left z-[561]">
                  Build features that allow users to organize and display
                  playlists with descriptions, artwork, and themes.
                  <br />
                  Introduce social tools that reward user creativity (e.g.,
                  playlist likes and followers) and provide more ways to make
                  playlists visible within friend groups and communities.
                </span>
              </div>
            </div>
            <div className='flex w-[149.571px] flex-col gap-[7.977px] items-start shrink-0 flex-nowrap relative z-[562]'>
              <div className='w-[149.571px] h-[23.931px] shrink-0 text-[0px] rounded-[3.989px] relative z-[563]'>
                <span className="block h-[13.087px] font-['Roboto'] text-[7.634375095367432px] font-bold leading-[8.947px] text-[#fff] relative text-left uppercase whitespace-nowrap z-[565] mt-[8.098px] mr-0 mb-0 ml-[5.733px]">
                  Stage 5: Decision to Leave or Stay
                </span>
                <div className='w-[149.571px] h-[23.931px] bg-[#7195ca] rounded-[3.989px] absolute top-0 left-0 z-[564]' />
              </div>
              <div className='flex w-[149.571px] h-[82.887px] pt-[11.966px] pr-[7.977px] pb-[11.966px] pl-[7.977px] gap-[3.989px] justify-center items-center shrink-0 flex-nowrap bg-[#664f74] rounded-[3.989px] relative z-[566]'>
                <span className="flex w-[133.617px] h-[72px] justify-start items-start grow shrink-0 basis-0 font-['Roboto'] text-[9.971427917480469px] font-normal leading-[11.966px] text-[#fff] relative text-left z-[567]">
                  Emily wants to decide whether to stay with Spotify or switch
                  to another platform that offers better customization and
                  social engagement.
                </span>
              </div>
              <div className='flex w-[149.571px] h-[174.5px] pt-[11.966px] pr-[7.977px] pb-[11.966px] pl-[7.977px] gap-[3.989px] justify-center items-center shrink-0 flex-nowrap bg-[#2b494e] rounded-[3.989px] relative z-[568]'>
                <span className="flex w-[133.617px] h-[172px] justify-start items-start grow shrink-0 basis-0 font-['Roboto'] text-[9.971427917480469px] font-normal leading-[11.966px] text-[#fff] relative text-left z-[569]">
                  She evaluates her satisfaction with Spotify’s features and
                  looks for reasons to stay.
                  <br />
                  Emily searches for news about upcoming features that could
                  improve the social aspects of Spotify.
                  <br />
                  She ultimately decides whether Spotify’s convenience outweighs
                  the frustration with its limited social tools.
                </span>
              </div>
              <div className='flex w-[149.571px] h-[100.337px] pt-[11.966px] pr-[7.977px] pb-[11.966px] pl-[7.977px] gap-[3.989px] justify-center items-center shrink-0 flex-nowrap bg-[#403c5b] rounded-[3.989px] relative z-[570]'>
                <span className="flex w-[133.617px] h-[96px] justify-start items-start grow shrink-0 basis-0 font-['Roboto'] text-[9.971427917480469px] font-normal leading-[11.966px] text-[#fff] relative text-left z-[571]">
                  “I like Spotify’s library, but it’s just not giving me what I
                  need socially.”
                  <br />
                  “If there was a way to customize my profile and show off my
                  music taste, I’d stay.”
                </span>
              </div>
              <div className='flex w-[149.571px] h-[74.287px] pt-[11.966px] pr-[7.977px] pb-[11.966px] pl-[7.977px] gap-[3.989px] justify-center items-center shrink-0 flex-nowrap bg-[rgba(135,174,180,0.16)] rounded-[3.989px] relative z-[572]' />
              <div className='flex w-[149.571px] h-[174.5px] pt-[11.966px] pr-[7.977px] pb-[11.966px] pl-[7.977px] gap-[3.989px] justify-center items-center shrink-0 flex-nowrap bg-[rgba(242,97,8,0.25)] rounded-[3.989px] relative z-[573]'>
                <span className="flex w-[133.617px] h-[152px] justify-start items-start grow shrink-0 basis-0 font-['Roboto'] text-[9.971427917480469px] font-medium leading-[11.966px] text-[#fff] relative text-left z-[574]">
                  Enable customizable profiles where users can showcase
                  playlists, favorite artists, concerts, and music preferences
                  in a visually appealing way.
                  <br />
                  Boost social engagement with collaborative playlists,
                  comments, and music taste matchmaking, giving users control
                  over their music identity.
                </span>
              </div>
            </div>
          </div>
          <div className='w-[2.81%] h-[0.65%] bg-[url(./assets/images/98ccb329-0426-4f16-b1ab-62f5bc2b898b.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[11.38%] left-[10.55%] z-[575]' />
          <div className='w-[12.215px] h-[12.215px] bg-[url(./assets/images/62ce8003-5d40-452c-983a-e712b52c6aa9.png)] bg-cover bg-no-repeat absolute top-[419.045px] left-[176.121px] z-[584]' />
          <div className='w-[147.973px] h-[51.894px] bg-[url(./assets/images/0d58ebdb-44a1-4037-9c03-e4d9d97e7f56.png)] bg-cover bg-no-repeat absolute top-[426.437px] left-[187.661px] z-[589]' />
          <div className='w-[2.81%] h-[0.65%] bg-[url(./assets/images/92aa5e65-7248-4198-b68d-9d04efad596d.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[12.11%] left-[10.55%] z-[583]' />
          <div className='w-[12.215px] h-[12.215px] bg-[url(./assets/images/34e4b475-9a8f-4a5b-91ae-e552f8c778d3.png)] bg-cover bg-no-repeat absolute top-[444.352px] left-[507.67px] z-[586]' />
          <div className='w-[12.215px] h-[12.215px] bg-[url(./assets/images/5797cf48-85d2-4a9a-95af-b264903adf9d.png)] bg-cover bg-no-repeat absolute top-[444.352px] left-[838.348px] z-[588]' />
          <div className='w-[161.71px] h-[29.639px] bg-[url(./assets/images/78a0706a-c68f-4eff-bb32-547d5033213e.png)] bg-cover bg-no-repeat absolute top-[448.736px] left-[519.886px] z-[591]' />
          <div className='w-[1024.191px] h-[3183.373px] bg-[url(./assets/images/7a9795ba-586f-4a7f-a3a7-1bbb18601981.png)] bg-cover bg-no-repeat absolute top-[451.34px] left-[8.267px] z-[590]' />
          <div className='w-[145.145px] h-[27.892px] bg-[url(./assets/images/61afd3ca-1338-41a8-96fb-c7a9f3a64230.png)] bg-cover bg-no-repeat absolute top-[450.487px] left-[693.203px] z-[592]' />
          <div className='w-[2.81%] h-[0.65%] bg-[url(./assets/images/91015437-9915-456a-b0b4-54cbf875beb7.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[12.8%] left-[10.47%] z-[582]' />
          <div className='w-[12.215px] h-[12.215px] bg-[url(./assets/images/b1a603a5-b25e-499d-9c96-4e5c8e00c209.png)] bg-cover bg-no-repeat absolute top-[472.27px] left-[335.788px] z-[585]' />
          <div className='w-[12.215px] h-[12.215px] bg-[url(./assets/images/3e1048ea-9ebb-4376-a1d7-fdd2eb2c673c.png)] bg-cover bg-no-repeat absolute top-[472.27px] left-[681.298px] z-[587]' />
        </div>
        <div className='w-[35.897px] h-[35.897px] bg-[#fff] rounded-[29.914px] absolute top-[895.185px] left-[407.458px] overflow-hidden z-[580]'>
          <div className='w-[32.118px] h-[32.118px] bg-[url(./assets/images/c6bbd0f9-8708-4a5b-b866-6c50d1fa8ae9.png)] bg-[length:100%_100%] bg-no-repeat relative z-[581] mt-[1.888px] mr-0 mb-0 ml-[1.889px]' />
        </div>
        <div className='w-[35.897px] h-[35.897px] bg-[#fff] rounded-[29.914px] absolute top-[938.808px] left-[441.485px] overflow-hidden z-[578]'>
          <div className='w-[32.118px] h-[32.118px] bg-[url(./assets/images/6f0c9257-6450-482e-93d0-eeba6cb8ee82.png)] bg-[length:100%_100%] bg-no-repeat relative z-[579] mt-[1.889px] mr-0 mb-0 ml-[1.889px]' />
        </div>
        <div className='flex w-[35.897px] h-[35.897px] gap-[129.629px] items-start flex-nowrap absolute top-[989.415px] left-[327.188px] z-[576]'>
          <div className='w-[35.897px] h-[35.897px] shrink-0 bg-[url(./assets/images/d75dbb5e-54a0-4c94-8037-a054e6485e85.png)] bg-[length:100%_100%] bg-no-repeat relative z-[577]' />
        </div>
      </div>
      <span className="block h-[68px] font-['Helvetica'] text-[40px] font-bold leading-[68px] text-[#fff] relative text-left whitespace-nowrap z-[597] mt-[45.843px] mr-0 mb-0 ml-[221px]">
        POV Statement
      </span>
      <div className='flex w-[1050px] h-[836px] justify-between items-center relative z-[596] mt-[63px] mr-0 mb-0 ml-[222px]'>
        <span className="flex w-[475px] h-[836px] justify-start items-start shrink-0 font-['Helvetica_Neue'] text-[22px] font-medium leading-[44px] text-[#fff] relative text-left z-[595]">
          After analyzing the data from the survey and user interviews, we
          identified several key insights:
          <br />
          Users show a strong preference for non-intrusive social features on
          Spotify, indicating that they value social interaction but desire more
          control over their engagement.
          <br />
          Intrusive features, such as real-time listening and public sharing,
          were less favored, highlighting that some users feel overwhelmed or
          uncomfortable with constant visibility.
          <br />
          Many Spotify users are drawn to personalized social experiences but
          want these interactions to fit seamlessly into their current usage
          without disrupting their listening habits.
        </span>
        <div className="w-[475px] h-[836px] shrink-0 font-['Helvetica_Neue'] text-[22px] font-medium leading-[44px] relative text-left z-[596]">
          <span className="font-['Helvetica_Neue'] text-[22px] font-medium leading-[44px] text-[#fff] relative text-left">
            A POV (Point of View) statement will help us create a meaningful and
            actionable problem statement that highlights the user’s needs and
            insights.
            <br />
            <br />
            Emily is a 28-year-old digital marketing strategist who enjoys
            connecting with others through music on Spotify. She needs a way to
            find friends more easily, showcase her music profile to inspire
            others, and create collaborative playlists with users who share her
            interests—
          </span>
          <span className="font-['Helvetica_Neue'] text-[22px] font-bold leading-[44px] text-[#fff] relative text-left">
            all without disrupting her core music-listening experience
          </span>
          <span className="font-['Helvetica_Neue'] text-[22px] font-medium leading-[44px] text-[#fff] relative text-left">
            . She values an unobtrusive approach to social features that allows
            her to engage socially and share her tastes, without overwhelming
            her or interfering with the app's primary function.
            <br />
          </span>
        </div>
      </div>
      <div className='w-[1047px] h-[124px] bg-[url(./assets/images/16579e80-b438-47e8-a4ab-eb78c4c5f510.png)] bg-[length:100%_100%] bg-no-repeat relative z-[45] mt-[71px] mr-0 mb-0 ml-[221px]'>
        <span className="flex h-[67.74%] justify-start items-start font-['Helvetica_Neue'] text-[60px] font-bold leading-[84px] text-[#fff] absolute top-[21.77%] left-[37.25%] text-left whitespace-nowrap z-[46]">
          Ideate
        </span>
      </div>
      <span className="block h-[83px] font-['Helvetica_Neue'] text-[40px] font-bold leading-[80px] text-[#fff] relative text-left whitespace-nowrap z-40 mt-[46px] mr-0 mb-0 ml-[222px]">
        How Might We(HMW)?
      </span>
      <span className="flex w-[1035px] h-[446px] justify-start items-start font-['Helvetica_Neue'] text-[22px] font-medium leading-[44px] text-[#fff] relative text-left z-[41] mt-[18px] mr-0 mb-0 ml-[221px]">
        How might we enable users to discover and connect with like-minded
        people on Spotify without disrupting their core music experience?
        <br />
        How might we allow users to showcase their music profiles and inspire
        others while keeping the interface simple and non-intrusive? <br />
        How might we design social features that seamlessly integrate into
        Spotify’s interface, enhancing community interaction without affecting
        music streaming? <br />
        How might we balance the need for social connection with a non-intrusive
        user experience that prioritizes uninterrupted music enjoyment?
        <br />
        How might we ensure users can easily find and follow friends on Spotify
        while preserving a streamlined, clutter-free app experience?
      </span>
      <span className="block h-[83px] font-['Helvetica_Neue'] text-[40px] font-bold leading-[80px] text-[#fff] relative text-left whitespace-nowrap z-[42] mt-[18px] mr-0 mb-0 ml-[228px]">
        Design Research
      </span>
      <div className="w-[1017px] h-[811px] font-['Helvetica_Neue'] text-[22px] font-medium leading-[44px] relative text-left z-[43] mt-[21px] mr-0 mb-0 ml-[228px]">
        <span className="font-['Helvetica_Neue'] text-[22px] font-medium leading-[44px] text-[#fff] relative text-left">
          I conducted thorough research on Spotify’s existing social features,
          analyzing how they currently enable user interaction and engagement.
          The goal was to identify areas where social experiences could be
          improved without sacrificing the platform’s core music-listening
          functionality.
          <br />
          <br />
          During this research, I reviewed the following social features on
          Spotify:
          <br />
        </span>
        <span className="font-['Helvetica_Neue'] text-[22px] font-bold leading-[44px] text-[#fff] relative text-left">
          Friend Activity Feed:{' '}
        </span>
        <span className="font-['Helvetica_Neue'] text-[22px] font-medium leading-[44px] text-[#fff] relative text-left">
          
          Displays what friends are currently listening to.
          <br />
        </span>
        <span className="font-['Helvetica_Neue'] text-[22px] font-bold leading-[44px] text-[#fff] relative text-left">
          Collaborative Playlists:{' '}
        </span>
        <span className="font-['Helvetica_Neue'] text-[22px] font-medium leading-[44px] text-[#fff] relative text-left">
          
          Allows multiple users to contribute to the same playlist.
          <br />
        </span>
        <span className="font-['Helvetica_Neue'] text-[22px] font-bold leading-[44px] text-[#fff] relative text-left">
          Group Sessions:{' '}
        </span>
        <span className="font-['Helvetica_Neue'] text-[22px] font-medium leading-[44px] text-[#fff] relative text-left">
          
          Lets users listen together in real-time, no matter where they are.
          <br />
        </span>
        <span className="font-['Helvetica_Neue'] text-[22px] font-bold leading-[44px] text-[#fff] relative text-left">
          Sharing to Social Media:{' '}
        </span>
        <span className="font-['Helvetica_Neue'] text-[22px] font-medium leading-[44px] text-[#fff] relative text-left">
          Users can share songs, playlists, and albums on platforms like
          Instagram and Facebook.
          <br />
        </span>
        <span className="font-['Helvetica_Neue'] text-[22px] font-bold leading-[44px] text-[#fff] relative text-left">
          Following Artists and Friends:{' '}
        </span>
        <span className="font-['Helvetica_Neue'] text-[22px] font-medium leading-[44px] text-[#fff] relative text-left">
          
          Users can follow others to stay updated on their listening habits.
          <br />
        </span>
        <span className="font-['Helvetica_Neue'] text-[22px] font-bold leading-[44px] text-[#fff] relative text-left">
          Spotify Wrapped:
        </span>
        <span className="font-['Helvetica_Neue'] text-[22px] font-medium leading-[44px] text-[#fff] relative text-left">
          
          An annual review of users' listening habits, which can be shared with
          friends.
          <br />
          <br />
          After going through all these features, I landed on a sorely lacking
          Profiles page. It currently offers minimal user customization and
          interaction, presenting a significant opportunity for optimization. By
          enhancing this area, Spotify can create a more dynamic and engaging
          social experience that allows users to showcase their music taste,
          find others with similar interests, and inspire their friends—without
          compromising the non-intrusive, seamless music experience that users
          value.
        </span>
      </div>
      <div className='w-[1020px] h-[499px] relative z-[86] mt-[90px] mr-0 mb-0 ml-[236px]'>
        <div className='w-[24.21%] h-[100.3%] bg-[url(./assets/images/25c375c6-e90c-4d39-841b-10e73aa71142.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[-0.15%] left-[21.25%] z-[73]'>
          <div className='w-[218.272px] h-[470.712px] bg-[url(./assets/images/54379f8a-9e57-4c26-8eea-a71b939fbb69.png)] bg-[length:100%_100%] bg-no-repeat relative z-[79] mt-[14.328px] mr-0 mb-0 ml-[14.321px]'>
            <div className='w-[4.31%] h-[2%] bg-[url(./assets/images/6359a66a-b846-4194-a0b3-5dbba2e97615.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[0.32%] left-[58.21%] z-[76]' />
            <div className='w-[12.09%] h-[1.52%] bg-[url(./assets/images/4446eefc-f4e2-475d-88d0-b0c8a838f7e4.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[0.56%] left-[43.5%] z-[78]' />
          </div>
        </div>
        <div className='w-[24.21%] h-[100.3%] bg-[url(./assets/images/52649b65-7817-4875-bd72-1a143dc7b34e.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[-0.15%] left-[49.48%] z-[64]'>
          <div className='w-[218.272px] h-[470.712px] bg-[url(./assets/images/972f76b4-4ba0-4a39-b76b-d4b9f90fc8a8.png)] bg-[length:100%_100%] bg-no-repeat relative z-[70] mt-[14.328px] mr-0 mb-0 ml-[14.321px]'>
            <div className='w-[4.31%] h-[2%] bg-[url(./assets/images/f3bc2a60-2949-4c9f-8199-a46362c961c0.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[0.32%] left-[58.21%] z-[67]' />
            <div className='w-[12.09%] h-[1.52%] bg-[url(./assets/images/0701455c-d895-469a-98e1-fa8c21ceccc2.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[0.56%] left-[43.5%] z-[69]' />
          </div>
        </div>
        <div className='w-[116.587px] h-[21.096px] bg-[url(./assets/images/e0522d3d-9873-4a03-b096-62cb38391343.png)] bg-cover bg-no-repeat absolute top-[51.998px] left-[148.984px] z-[82]' />
        <span className="flex w-[147px] h-[71px] justify-start items-start font-['Helvetica_Neue'] text-[12px] font-medium leading-[24px] text-[#fff] absolute top-[60px] left-[2px] text-left z-[60]">
          No ability to customize the fade that happens on your profile.
        </span>
        <div className='w-[12.215px] h-[12.215px] bg-[url(./assets/images/5098fe68-c6f2-46a2-b62c-3015822ec558.png)] bg-cover bg-no-repeat absolute top-[65px] left-[137px] z-[52]' />
        <div className='w-[203.797px] h-[21.914px] bg-[url(./assets/images/073230e0-3a93-4a1b-8f20-dc6328aa6adb.png)] bg-cover bg-no-repeat absolute top-[68.983px] left-[626.144px] z-[80]' />
        <span className="flex w-[168px] h-[42px] justify-start items-start font-['Helvetica_Neue'] text-[12px] font-medium leading-[24px] text-[#fff] absolute top-[73px] left-[852px] text-left z-[55]">
          No feature to search in your following.
        </span>
        <div className='w-[12.215px] h-[12.215px] bg-[url(./assets/images/d6b68299-8117-4041-b739-723e06cf0b80.png)] bg-cover bg-no-repeat absolute top-[83px] left-[830px] z-[48]' />
        <div className='w-[218.811px] h-[28.092px] bg-[url(./assets/images/f8a27268-3ca5-4045-bb38-7ca28a61d5b8.png)] bg-cover bg-no-repeat absolute top-[125.998px] left-[606.144px] z-[85]' />
        <span className="flex w-[168px] h-[42px] justify-start items-start font-['Helvetica_Neue'] text-[12px] font-medium leading-[24px] text-[#fff] absolute top-[136px] left-[846px] text-left z-[56]">
          Unable to distinguish between artist and friends.
        </span>
        <div className='w-[12.215px] h-[12.215px] bg-[url(./assets/images/0061e28e-cf19-4a5e-84c6-8d8e7a518a2c.png)] bg-cover bg-no-repeat absolute top-[146px] left-[826px] z-[54]' />
        <div className='w-[112.724px] h-[18.465px] bg-[url(./assets/images/0925f3fd-351d-40f9-bc47-617dbd2dcc2d.png)] bg-cover bg-no-repeat absolute top-[168.999px] left-[152.538px] z-[81]' />
        <span className="flex w-[147px] h-[71px] justify-start items-start font-['Helvetica_Neue'] text-[12px] font-medium leading-[24px] text-[#fff] absolute top-[174px] left-0 text-left z-[59]">
          Unable to tell if this user has any common friends with.
        </span>
        <div className='w-[12.215px] h-[12.215px] bg-[url(./assets/images/ff40722e-a64d-4b93-a7bb-7f3523b164cb.png)] bg-cover bg-no-repeat absolute top-[179px] left-[140px] z-[51]' />
        <div className='w-[105.702px] h-[29.217px] bg-[url(./assets/images/5113740c-19b5-4ca0-97f3-b764e1be28fd.png)] bg-cover bg-no-repeat absolute top-[239.994px] left-[149.97px] z-[83]' />
        <span className="flex w-[118px] h-[71px] justify-start items-start font-['Helvetica_Neue'] text-[12px] font-medium leading-[24px] text-[#fff] absolute top-[254px] left-[14px] text-left z-[58]">
          Playlists are listed as recently created instead of popular.
        </span>
        <div className='w-[12.215px] h-[12.215px] bg-[url(./assets/images/2d82f909-2bb0-414b-8e14-908fe8682198.png)] bg-cover bg-no-repeat absolute top-[260px] left-[138px] z-50' />
        <div className='w-[218.811px] h-[28.092px] bg-[url(./assets/images/60ca7626-29ef-4358-baa2-ba240b73f70c.png)] bg-cover bg-no-repeat absolute top-[289.998px] left-[606.016px] z-[86]' />
        <span className="flex w-[168px] h-[101px] justify-start items-start font-['Helvetica_Neue'] text-[12px] font-medium leading-[24px] text-[#fff] absolute top-[293px] left-[846px] text-left z-[57]">
          Difficulty finding any friends with no profile pictures/no incentive
          to create profile pics either.
        </span>
        <div className='w-[12.215px] h-[12.215px] bg-[url(./assets/images/790314b3-fdb4-40e4-851d-2feb81deff70.png)] bg-cover bg-no-repeat absolute top-[310px] left-[826px] z-[49]' />
        <div className='w-[188.447px] h-[21.006px] bg-[url(./assets/images/4beaa85e-79f5-4d4e-b783-a88625fb7733.png)] bg-cover bg-no-repeat absolute top-[343.995px] left-[147.09px] z-[84]' />
        <span className="flex w-[118px] h-[119px] justify-start items-start font-['Helvetica_Neue'] text-[12px] font-medium leading-[24px] text-[#fff] absolute top-[355px] left-[36px] text-left z-[61]">
          See all playlists button leads you to a unsortable long list with no
          features to help search.
        </span>
        <div className='w-[12.215px] h-[12.215px] bg-[url(./assets/images/b3f286ab-a375-4448-a667-ba5edde71110.png)] bg-cover bg-no-repeat absolute top-[359px] left-[134px] z-[53]' />
      </div>
      <div className='w-[1047px] h-[124px] bg-[url(./assets/images/1cba4d19-f6a9-43a2-ae41-d06b7d255954.png)] bg-[length:100%_100%] bg-no-repeat relative z-[7] mt-[74px] mr-0 mb-0 ml-[217px]'>
        <span className="flex h-[67.74%] justify-start items-start font-['Helvetica_Neue'] text-[60px] font-bold leading-[84px] text-[#fff] absolute top-[21.77%] left-[37.25%] text-left whitespace-nowrap z-[8]">
          Prototype
        </span>
      </div>
      <span className="block h-[83px] font-['Helvetica_Neue'] text-[40px] font-bold leading-[80px] text-[#fff] relative text-left whitespace-nowrap z-[3] mt-0 mr-0 mb-0 ml-[234px]">
        Sketching: Outlining Basic Layout
      </span>
      <div className='w-[771px] h-[837px] relative z-[9] mt-0 mr-0 mb-0 ml-[234px]'>
        <span className="flex w-[516px] h-[264px] justify-start items-start font-['Helvetica_Neue'] text-[22px] font-medium leading-[44px] text-[#fff] absolute top-0 left-[calc(50%-385.5px)] text-left z-[9]">
          By sketching, I can go through fast iteration loops for how a layout
          for a profile screen would designed. I was able to settle on a grid &
          section system that allowed for uniform fitting of icons.
        </span>
        <div className='w-[225px] h-[733px] bg-[url(./assets/images/ac61f8e7857e24037a8f086399c3e1ba0ad3f206.png)] bg-cover bg-no-repeat rounded-[15px] absolute top-[104px] left-[546px] z-[1]' />
        <div className='w-[305px] h-[611px] bg-[url(./assets/images/1fbe0b82ebff0ca26710367e0fc4a709ca42b5a3.png)] bg-cover bg-no-repeat rounded-[15px] absolute top-[226px] left-0 z-[2]' />
      </div>
      <div className='w-[976.124px] h-[808px] relative z-[23] mt-[100px] mr-0 mb-0 ml-[234px]'>
        <span className="flex h-[77px] justify-start items-start font-['Helvetica_Neue'] text-[40px] font-bold leading-[77px] text-[#fff] absolute top-0 left-[calc(50%-488.06px)] text-left whitespace-nowrap z-[4]">
          Low Fidelity Wireframes
        </span>
        <div className='w-[35.8%] h-[87.69%] bg-[url(./assets/images/e4c0b454-4982-40c6-9952-d591be300868.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[7.58%] left-[64.28%] z-[23]'>
          <div className='w-[309.438px] h-[666.921px] bg-[url(./assets/images/79c1c171-e848-4e6c-8470-d829a6e37de7.png)] bg-[length:100%_100%] bg-no-repeat relative z-[29] mt-[19.988px] mr-0 mb-0 ml-[19.99px]'>
            <div className='w-[4.11%] h-[1.91%] bg-[url(./assets/images/ed90f7c1-d87d-47b5-a4e0-f5750234b3b2.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[0.37%] left-[58.31%] z-[26]' />
            <div className='w-[11.88%] h-[1.43%] bg-[url(./assets/images/6e0b9be3-2d59-4885-ade8-a0356a994472.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[0.61%] left-[43.6%] z-[28]' />
          </div>
        </div>
        <div className="w-[604px] h-[704px] font-['Helvetica_Neue'] text-[22px] font-medium leading-[44px] absolute top-[104px] left-1/2 text-left translate-x-[-80.8%] translate-y-0 z-10">
          <span className="font-['Helvetica_Neue'] text-[22px] font-medium leading-[44px] text-[#fff] relative text-left">
            In designing Spotify profiles, I leveraged low-fidelity wireframes
            to streamline the early stages of development. The following points
            highlight how this approach helped refine key design elements:
            <br />
          </span>
          <span className="font-['Helvetica_Neue'] text-[22px] font-bold leading-[44px] text-[#fff] relative text-left">
            Icon Sizing:{' '}
          </span>
          <span className="font-['Helvetica_Neue'] text-[22px] font-medium leading-[44px] text-[#fff] relative text-left">
            
            The wireframes provided a clear visual representation, allowing me
            to experiment with different icon sizes and placements to ensure a
            balanced look.
            <br />
          </span>
          <span className="font-['Helvetica_Neue'] text-[22px] font-bold leading-[44px] text-[#fff] relative text-left">
            User Navigation:{' '}
          </span>
          <span className="font-['Helvetica_Neue'] text-[22px] font-medium leading-[44px] text-[#fff] relative text-left">
            
            By focusing on structure over detail, I was able to identify
            potential navigation paths and improve the user experience early on.
            <br />
          </span>
          <span className="font-['Helvetica_Neue'] text-[22px] font-bold leading-[44px] text-[#fff] relative text-left">
            Overall Design Aesthetic:{' '}
          </span>
          <span className="font-['Helvetica_Neue'] text-[22px] font-medium leading-[44px] text-[#fff] relative text-left">
            
            The simplicity of the wireframes enabled quick adjustments to the
            layout and alignment, ensuring consistency in the overall design.
            <br />
            This method allowed for rapid iteration, optimizing feedback loops
            and leading to a more polished final product.
          </span>
        </div>
      </div>
      <div className='flex w-[919.918px] h-[707px] justify-between items-center relative z-[32] mt-[26px] mr-0 mb-0 ml-[288.207px]'>
        <div className='w-[349.418px] h-[708.5px] shrink-0 bg-[url(./assets/images/8bac2ba6-f477-4720-a160-39308ab39211.png)] bg-[length:100%_100%] bg-no-repeat relative z-[14]'>
          <div className='w-[309.438px] h-[666.921px] bg-[url(./assets/images/d1f86017-5795-4efc-baaa-89198ef2ff95.png)] bg-[length:100%_100%] bg-no-repeat relative z-20 mt-[19.988px] mr-0 mb-0 ml-[19.99px]'>
            <div className='w-[4.11%] h-[1.91%] bg-[url(./assets/images/b902ddf3-2129-47cb-9dde-ae2b39dcd9eb.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[0.37%] left-[58.31%] z-[17]' />
            <div className='w-[11.88%] h-[1.43%] bg-[url(./assets/images/c5a1eaf7-7846-4c4d-a0b3-3c8a414ceb66.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[0.61%] left-[43.6%] z-[19]' />
          </div>
        </div>
        <div className='w-[349.418px] h-[708.5px] shrink-0 bg-[url(./assets/images/12cfde9b-1fda-444f-bb35-f61921d143fb.png)] bg-[length:100%_100%] bg-no-repeat relative z-[32]'>
          <div className='w-[309.438px] h-[666.921px] bg-[url(./assets/images/89cbf560-6dba-4364-8e26-bd45a2faebf6.png)] bg-[length:100%_100%] bg-no-repeat relative z-[38] mt-[19.988px] mr-0 mb-0 ml-[19.99px]'>
            <div className='w-[4.11%] h-[1.91%] bg-[url(./assets/images/b55a31d6-2b68-45a6-a480-1ea54d374484.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[0.37%] left-[58.31%] z-[35]' />
            <div className='w-[11.88%] h-[1.43%] bg-[url(./assets/images/4f79740f-ffa0-47dd-bb9b-a164691ee0a0.png)] bg-[length:100%_100%] bg-no-repeat absolute top-[0.61%] left-[43.6%] z-[37]' />
          </div>
        </div>
      </div>
      <span className="block h-[77px] font-['Helvetica_Neue'] text-[40px] font-bold leading-[77px] text-[#fff] relative text-left whitespace-nowrap z-[5] mt-[77px] mr-0 mb-0 ml-[231px]">
        High-Fidelity Wireframe
      </span>
      <div className="w-[1048px] h-[689px] font-['Helvetica_Neue'] text-[21px] font-medium leading-[42px] relative text-left z-[11] mt-[38px] mr-0 mb-0 ml-[231px]">
        <span className="font-['Helvetica_Neue'] text-[21px] font-medium leading-[42px] text-[#fff] relative text-left">
          Once I tested and resolved all usability issues, I began designing the
          final screens in Figma for Spotify Profiles.
          <br />
          <br />
        </span>
        <span className="font-['Helvetica_Neue'] text-[21px] font-bold leading-[42px] text-[#fff] relative text-left">
          Font:{' '}
        </span>
        <span className="font-['Helvetica_Neue'] text-[21px] font-medium leading-[42px] text-[#fff] relative text-left">
          
        </span>
        <span className="font-['Helvetica_Neue'] text-[21px] font-medium leading-[42px] text-[#fff] relative text-left">
          Avenir Next
        </span>
        <span className="font-['Helvetica_Neue'] text-[21px] font-medium leading-[42px] text-[#fff] relative text-left">
          
          (used to maintain brand consistency).
          <br />
        </span>
        <span className="font-['Helvetica_Neue'] text-[21px] font-bold leading-[42px] text-[#fff] relative text-left">
          Platform:{' '}
        </span>
        <span className="font-['Helvetica_Neue'] text-[21px] font-medium leading-[42px] text-[#fff] relative text-left">
          
          iOS.
          <br />
          <br />
          Major challenges I addressed:
          <br />
        </span>
        <span className="font-['Helvetica_Neue'] text-[21px] font-bold leading-[42px] text-[#fff] relative text-left">
          Sizing:{' '}
        </span>
        <span className="font-['Helvetica_Neue'] text-[21px] font-medium leading-[42px] text-[#fff] relative text-left">
          
          I refined the sizing of elements to ensure balance between diverse
          profile structures and simplicity in usability, making it intuitive
          without overwhelming the user.
          <br />
        </span>
        <span className="font-['Helvetica_Neue'] text-[21px] font-bold leading-[42px] text-[#fff] relative text-left">
          Creativity and Aesthetics:{' '}
        </span>
        <span className="font-['Helvetica_Neue'] text-[21px] font-medium leading-[42px] text-[#fff] relative text-left">
          
          While maintaining usability, I left room for creativity, ensuring
          profiles had a unique and visually appealing design that would attract
          users to engage more with the feature.
          <br />
        </span>
        <span className="font-['Helvetica_Neue'] text-[21px] font-bold leading-[42px] text-[#fff] relative text-left">
          User Engagement:{' '}
        </span>
        <span className="font-['Helvetica_Neue'] text-[21px] font-medium leading-[42px] text-[#fff] relative text-left">
          
          The final design aimed to make profiles pop, giving users a sense of
          personalization, which enhances the likelihood of feature adoption.
          <br />
          <br />
          By resolving these key challenges, the profiles now offer a sleek,
          user-friendly experience while retaining a personalized, unique feel.
        </span>
        <div style={instructionStyle}>Feel free to scroll</div>
      </div>

      <div style={phoneContainerStyle}>
          <PhoneScroll image={MainGuyPinkSVG} />
          <PhoneScroll image={MainGirlPinkSVG} />
          <PhoneScroll image={PodMainSVG} />
        </div>

      <div className='w-[1047px] h-[124px] bg-[url(./assets/images/aa2e7038-ba33-4219-85cd-1f8ae75efae0.png)] bg-[length:100%_100%] bg-no-repeat relative z-[622] mt-[100px] mr-0 mb-0 ml-[196px]'>
        <span className="flex h-[67.74%] justify-start items-start font-['Helvetica_Neue'] text-[60px] font-bold leading-[84px] text-[#fff] absolute top-[21.77%] left-[37.25%] text-left whitespace-nowrap z-[623]">
          
          Test
        </span>
      </div>
      <span className="block h-[77px] font-['Helvetica_Neue'] text-[40px] font-bold leading-[77px] text-[#fff] relative text-left whitespace-nowrap z-[625] mt-[24px] mr-0 mb-0 ml-[196px]">
        User Testing
      </span>
      <div className='w-[1047px] h-[546px] relative z-[628] mt-[10px] mr-0 mb-0 ml-[196px]'>
        <span className="flex w-[385px] h-[546px] justify-start items-start font-['Helvetica_Neue'] text-[21px] font-medium leading-[42px] text-[#fff] absolute top-0 left-[calc(50%--138.5px)] text-left z-[626]">
          With all the screens of the high-fidelity wireframes were ready. I
          wanted to speak to the users to get a feel for if they would like the
          solution. <br />
          <br />
          Spotify Users - Those who are currently using Spotify as their main
          music player
          <br />
          <br />
          Non-Spotify Users - Those who are not currently using Spotify as their
          main music player, and are using another music player instead.
        </span>
        <div className='w-[642px] h-[334px] bg-[url(./assets/images/61174357af57fcf7ba57f50baba6d0431e437486.png)] bg-cover bg-no-repeat absolute top-[15px] left-0 z-[628]' />
      </div>
      <span className="block h-[77px] font-['Helvetica_Neue'] text-[40px] font-bold leading-[77px] text-[#fff] relative text-left whitespace-nowrap z-[624] mt-[-1px] mr-0 mb-0 ml-[197px]">
        Success Metrics
      </span>
      <div className="w-[1062px] h-[546px] font-['Helvetica_Neue'] text-[21px] font-medium leading-[42px] relative text-left z-[627] mt-[46px] mr-0 mb-0 ml-[197px]">
        <span className="font-['Helvetica_Neue'] text-[21px] font-bold leading-[42px] text-[#fff] relative text-left">
          The main issue with the application was the lack of social features in
          a non-intrusive way. We took advantage of this by redesigning a
          heavily underused feature which is the profiles section. Here are a
          Few Key Metrics that will help us tracking the success of this social
          addition. <br />
          <br />
          New Users:
          <br />
        </span>
        <span className="font-['Helvetica_Neue'] text-[21px] font-medium leading-[42px] text-[#fff] relative text-left">
          % of New Users Setting Up Profiles:Track how many new users customize
          their profiles within a week of signing up.
          <br />
        </span>
        <span className="font-['Helvetica_Neue'] text-[21px] font-bold leading-[42px] text-[#fff] relative text-left">
          Revisitation: <br />
        </span>
        <span className="font-['Helvetica_Neue'] text-[21px] font-medium leading-[42px] text-[#fff] relative text-left">
          Profile Revisit Rate:Monitor how often users return to view their own
          or others’ profiles.
          <br />
        </span>
        <span className="font-['Helvetica_Neue'] text-[21px] font-bold leading-[42px] text-[#fff] relative text-left">
          Profile Customizations:
          <br />
        </span>
        <span className="font-['Helvetica_Neue'] text-[21px] font-medium leading-[42px] text-[#fff] relative text-left">
          % of Users Customizing Their Profile:Measure the percentage of total
          users actively personalizing their profiles.
        </span>
      </div>
      <div className='w-[1047px] h-[124px] bg-[url(./assets/images/2605fc25-257b-43a7-9619-9a1f6a643d0e.png)] bg-[length:100%_100%] bg-no-repeat relative z-[630] mt-[84px] mr-0 mb-0 ml-[196px]'>
        <span className="flex h-[67.74%] justify-start items-start font-['Helvetica_Neue'] text-[60px] font-bold leading-[84px] text-[#fff] absolute top-[16.13%] left-[34.38%] text-left whitespace-nowrap z-[631]">
          Final Results
        </span>
      </div>
      <span className="block h-[81.424px] font-['Helvetica_Neue'] text-[40px] font-bold leading-[80px] text-[#fff] relative text-left whitespace-nowrap z-[632] mt-[50px] mr-0 mb-0 ml-[196px]">
        Learning
      </span>
      <div className="w-[1048px] h-[936px] font-['Helvetica_Neue'] text-[21px] font-medium leading-[42px] relative text-left z-[641] mt-[-0.42px] mr-0 mb-0 ml-[196px]">
        <span className="font-['Helvetica_Neue'] text-[21px] font-medium leading-[42px] text-[#fff] relative text-left">
          <br />
        </span>
        <span className="font-['Helvetica_Neue'] text-[25px] font-bold leading-[42px] text-[#fff] relative text-left">
          Customer-Centricity
          <br />
        </span>
        <span className="font-['Helvetica_Neue'] text-[21px] font-medium leading-[42px] text-[#fff] relative text-left">
          <br />
          Working on improving Spotify's social features has been an eye-opening
          experience in understanding what truly matters to users. Initially, I
          believed enhancing the social features would primarily involve adding
          new functionalities. However, throughout the project, I realized that
          what users truly valued was a more intuitive and seamless experience
          when interacting with friends and discovering new music. While we
          introduced some new features, the real focus was on simplifying the
          user journey and reducing any friction in how people connect and share
          music on the platform.
          <br />
          <br />
        </span>
        <span className="font-['Helvetica_Neue'] text-[25px] font-bold leading-[42px] text-[#fff] relative text-left">
          User Insights and Iterative Design
          <br />
        </span>
        <span className="font-['Helvetica_Neue'] text-[21px] font-medium leading-[42px] text-[#fff] relative text-left">
          <br />
          This project underscored the importance of gathering user feedback
          early and often. By engaging directly with users and observing how
          they interact with the social features, I learned the value of
          iterative design—continually refining the features based on real user
          behavior and preferences. This approach ensured that each update was
          not only well-received but also met the evolving needs of Spotify's
          diverse user base.
          <br />
          <br />
          My experience with Spotify's social features project has deepened my
          appreciation for the balance between creativity and user expectations.
          It's not just about innovating with new ideas but also about enhancing
          what already works, making the app more intuitive and enjoyable. I
          aimed to contribute to Spotify's legacy of exceptional design by
          aligning my efforts with the app's existing aesthetic while pushing
          for meaningful improvements in the social experience.
        </span>
      </div>
      <div className='w-[940px] h-[136px] relative z-[640] mt-[70px] mr-0 mb-0 ml-[237px]'>
        <span className="flex h-[59.56%] justify-start items-start font-['Helvetica_Neue'] text-[40px] font-bold leading-[80px] text-[#fff] absolute top-0 left-0 text-left whitespace-nowrap z-[633]">
          65+
        </span>
        <span className="flex h-[59.56%] justify-start items-start font-['Helvetica_Neue'] text-[40px] font-bold leading-[80px] text-[#fff] absolute top-0 left-[63.19%] text-left whitespace-nowrap z-[637]">
          8+
        </span>
        <span className="flex h-[59.56%] justify-start items-start font-['Helvetica_Neue'] text-[40px] font-bold leading-[80px] text-[#fff] absolute top-[4.41%] left-1/4 text-left whitespace-nowrap z-[635]">
          1.5 months
        </span>
        <span className="flex h-[59.56%] justify-start items-start font-['Helvetica_Neue'] text-[40px] font-bold leading-[80px] text-[#fff] absolute top-[4.41%] left-[88.94%] text-left whitespace-nowrap z-[639]">
          15+
        </span>
        <span className="flex h-[59.56%] justify-start items-start font-['Helvetica_Neue'] text-[24px] font-bold leading-[80px] text-[#fff] absolute top-[38.97%] left-[60.43%] text-left whitespace-nowrap z-[638]">
          Screens
        </span>
        <span className="flex h-[59.56%] justify-start items-start font-['Helvetica_Neue'] text-[24px] font-bold leading-[80px] text-[#fff] absolute top-[40.44%] left-0 text-left whitespace-nowrap z-[634]">
          Users
        </span>
        <span className="flex h-[59.56%] justify-start items-start font-['Helvetica_Neue'] text-[24px] font-bold leading-[80px] text-[#fff] absolute top-[40.44%] left-[30.43%] text-left whitespace-nowrap z-[636]">
          Timeline
        </span>
        <span className="flex h-[59.56%] justify-start items-start font-['Helvetica_Neue'] text-[24px] font-bold leading-[80px] text-[#fff] absolute top-[40.44%] left-[87.55%] text-left whitespace-nowrap z-[640]">
          Iterations
        </span>
      </div>
      <span className="flex w-[531px] h-[1.43%] justify-start items-start font-['Helvetica_Neue'] text-[24px] font-medium leading-[48px] text-[#fff] absolute top-[3.88%] left-[13.62%] text-left z-[646]">
        While working on my own ideas, I focused on perfecting Design Thinking,
        which helped create a structured approach to understanding the
        relationship between users and the product being developed. Design
        Thinking clarifies user needs and identifies solutions to address them
        effectively.
        <br />
      </span>
      <div className='w-[75.82%] h-[3.5%] absolute top-[4.57%] left-[13.62%] z-[648]'>
        <span className="flex w-[423px] h-[29.99%] justify-start items-start font-['Helvetica_Neue'] text-[55px] font-bold leading-[77px] text-[#fff] absolute top-0 left-[55.45%] text-left z-[648]">
          Design Thinking
        </span>
        <div className='w-[537px] h-[238px] bg-[url(./assets/images/d000c9a3e5df26f422012446de06528fa9400a94.png)] bg-cover bg-no-repeat absolute top-[88px] left-[554px] z-[644]' />
        <div className="w-[531px] h-[646px] font-['Helvetica_Neue'] text-[24px] font-normal leading-[33.6px] absolute top-[191px] left-0 text-left z-[645]">
          <span className="font-['Helvetica_Neue'] text-[24px] font-medium leading-[33.6px] text-[#fff] relative text-left">
            Before diving into the specific steps, here's an overview:
            <br />
          </span>
          <span className="font-['Helvetica_Neue'] text-[24px] font-normal leading-[33.6px] text-[#fff] relative text-left">
            <br />
          </span>
          <span className="font-['Helvetica_Neue'] text-[24px] font-bold leading-[33.6px] text-[#fff] relative text-left">
            Empathize
          </span>
          <span className="font-['Helvetica_Neue'] text-[24px] font-normal leading-[33.6px] text-[#fff] relative text-left">
            
            -
          </span>
          <span className="font-['Helvetica_Neue'] text-[24px] font-medium leading-[33.6px] text-[#fff] relative text-left">
            Identify the users' pain points and challenges with the current
            product. <br />
          </span>
          <span className="font-['Helvetica_Neue'] text-[24px] font-normal leading-[33.6px] text-[#fff] relative text-left">
            <br />
          </span>
          <span className="font-['Helvetica_Neue'] text-[24px] font-bold leading-[33.6px] text-[#fff] relative text-left">
            Define
          </span>
          <span className="font-['Helvetica_Neue'] text-[24px] font-normal leading-[33.6px] text-[#fff] relative text-left">
            
            -
          </span>
          <span className="font-['Helvetica_Neue'] text-[24px] font-medium leading-[33.6px] text-[#fff] relative text-left">
            Narrow down specific problems that the new features will solve.
            <br />
          </span>
          <span className="font-['Helvetica_Neue'] text-[24px] font-normal leading-[33.6px] text-[#fff] relative text-left">
            <br />
          </span>
          <span className="font-['Helvetica_Neue'] text-[24px] font-bold leading-[33.6px] text-[#fff] relative text-left">
            Ideation
          </span>
          <span className="font-['Helvetica_Neue'] text-[24px] font-normal leading-[33.6px] text-[#fff] relative text-left">
            
            -
          </span>
          <span className="font-['Helvetica_Neue'] text-[24px] font-medium leading-[33.6px] text-[#fff] relative text-left">
            Brainstorm possible solutions or feature improvements. <br />
          </span>
          <span className="font-['Helvetica_Neue'] text-[24px] font-normal leading-[33.6px] text-[#fff] relative text-left">
            <br />
          </span>
          <span className="font-['Helvetica_Neue'] text-[24px] font-bold leading-[33.6px] text-[#fff] relative text-left">
            Prototype
          </span>
          <span className="font-['Helvetica_Neue'] text-[24px] font-normal leading-[33.6px] text-[#fff] relative text-left">
            
            -
          </span>
          <span className="font-['Helvetica_Neue'] text-[24px] font-medium leading-[33.6px] text-[#fff] relative text-left">
            Suggest initial mockups, wireframes, or sketches of the improved
            feature. <br />
          </span>
          <span className="font-['Helvetica_Neue'] text-[24px] font-normal leading-[33.6px] text-[#fff] relative text-left">
            <br />
          </span>
          <span className="font-['Helvetica_Neue'] text-[24px] font-bold leading-[33.6px] text-[#fff] relative text-left">
            Iterate
          </span>
          <span className="font-['Helvetica_Neue'] text-[24px] font-normal leading-[33.6px] text-[#fff] relative text-left">
            
            -
          </span>
          <span className="font-['Helvetica_Neue'] text-[24px] font-medium leading-[33.6px] text-[#fff] relative text-left">
            Discuss how you would test the new feature with users and gather
            feedback for iterative improvements.
          </span>
        </div>
        <div className='w-[388px] h-[242px] bg-[url(./assets/images/c6e12d2717046580f34e399a877c5f2a70e0df9d.png)] bg-cover bg-no-repeat absolute top-[320px] left-[620px] z-[643]' />
      </div>
    </div>
  );
}
