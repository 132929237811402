import React from 'react';
import './App.css';
import { Navbar } from './components/Navbar.js';
import { Route, Routes, useLocation } from 'react-router-dom';
import { WorkPage } from './pages/WorkPage';
import { AboutPage } from './pages/AboutPage'
import { EuropeanTT } from './pages/projects/EuropeanTT';
import { JodtPage } from './pages/projects/JodtProjectPage';
import { ConstructionPage } from './pages/ConstructionPage';
import { PhoneScroll } from './pages/iPhoneScroll';
import SpotifyDesignCase from './pages/SpotifyDesignCaseCode';
import { Footer } from './components/Footer';

function App() {

  return (
    <div className="app-container" style={{
      transform: 'scale(1.5)',
      transformOrigin: 'top left',
      width: '66.6666666%', // 100% / 1.25 to maintain the same visible area
      height: '66.66666%', // 100% / 1.25 to maintain the same visible area
      overflow: 'auto'
    }}>
      <div className="body w-full">
        <Navbar/>
        <Routes>
          <Route path='/' element={<WorkPage/>}/>
          <Route path='/resume' element={<div></div>}/>
          <Route path='/about' element={<AboutPage/>}/>
          <Route path='/construction' element={<ConstructionPage/>}/>
          <Route path='/EuropeanTT' element={<EuropeanTT/>}/>
          <Route path='/jodt' element={<JodtPage/>}/>
          <Route path='/SpotifyDesignCase' element={<SpotifyDesignCase/>}/>
        </Routes>
        <Footer/>
      </div>
    </div>
  );
}

export default App;