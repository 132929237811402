import { useEffect, useState } from "react"
import { HashLink as Link } from 'react-router-hash-link';
import { useHeadsObserver } from "../../hooks/hooks";
import TasteTestVisualization from '../../imgs/TasteTestVisualization.jpg';
import TasteTest from '../../imgs/TasteTest.jpg';

export function EuropeanTT() {
    const {activeId} = useHeadsObserver()
    const [currentSection, setCurrentSection] = useState('')

    useEffect(() =>{
        setCurrentSection(activeId)
    }, [activeId])
    
    const scrollWithOffset = (el) => {
        const yOffset = -145; 
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: yCoordinate, behavior: 'smooth' }); 
    }


    console.log(currentSection)

    return(
        <div className="pt-20">
            <div className='flex flex-col md:flex-row main-body items-center justify-end md:items-start md:justify-end xl:justify-center px-[110px] mb-[60px]'>
                <div className='none md:block flex flex-col space-y-4 md:space-y-4 md:items-start fixed md:left-[110px] md:top-[148px] mb-10'>
                    <div className='flex flex-col font-chivo text-base justify-center' 
                        style={currentSection === 'st-project' ? {textDecorationLine: 'underline'} : {textDecorationLine: 'none'}}>
                        <Link to='#st-project' scroll={el => scrollWithOffset(el)} onClick={() => setCurrentSection('st-project')}>
                            PROJECT
                        </Link>
                    </div>
                    <div className='flex flex-col font-chivo text-base justify-center'
                        style={currentSection === 'st-overview' ? {textDecorationLine: 'underline'} : {textDecorationLine: 'none'}}>
                        <Link to='#st-overview' scroll={el => scrollWithOffset(el)} onClick={() => setCurrentSection('st-overview')}>
                            OVERVIEW
                        </Link>
                    </div>
                    <div className='font-chivo text-base text-center'
                        style={currentSection === 'st-final' ? {textDecorationLine: 'underline'} : {textDecorationLine: 'none'}}>
                        FINAL PRODUCT
                    </div>
                    <div className='flex flex-col font-chivo text-base justify-center' 
                        style={currentSection === 'st-problem' ? {textDecorationLine: 'underline'} : {textDecorationLine: 'none'}}>
                        <Link to='#st-problem' scroll={el => scrollWithOffset(el)} onClick={() => setCurrentSection('st-problem')}>
                            PROBLEM
                        </Link>
                    </div>
                    <div className='flex flex-col font-chivo text-base justify-center'
                        style={currentSection === 'st-users' ? {textDecorationLine: 'underline'} : {textDecorationLine: 'none'}}>
                        USERS
                    </div>
                    <div className='flex flex-col font-chivo text-base justify-center'
                        style={currentSection === 'st-solution' ? {textDecorationLine: 'underline'} : {textDecorationLine: 'none'}}>
                        SOLUTION
                    </div>
                    <div className='flex flex-col font-chivo text-base justify-center'
                        style={currentSection === 'st-reflection' ? {textDecorationLine: 'underline'} : {textDecorationLine: 'none'}}>
                        REFLECTION
                    </div>
                </div>
                <div className='project w-[320px] md:pl-[110px] md:w-[480px] lg:w-[680px] space-y-10'>
                    <div className='mb-10 w-full rounded-lg border-2 border-black shadow-[8px_8px_0px_0px_rgba(0,0,0,1)] overflow-hidden'>   
                        <img src={TasteTestVisualization} alt="Taste Test Visualization" className="w-full h-auto object-cover" />
                    </div>
                    <div className='flex flex-col space-y-10 md:flex-row md:space-x-10 md:space-y-0'>
                        <div className='proj&overview space-y-10'>
                            <div className='proj'>
                                <h1 className='font-chivo text-base py-2 border-b-[1px] border-black mb-2' id='st-project'>
                                    PROJECT
                                </h1>
                                <div className='font-sans text-2xl mb-3'>
                                    <h2 className='font-black'>
                                        European Taste Test
                                    </h2>
                                    <h2 className='italic'>
                                        Local Restaurant Discovery of 10,000 authentic restaurants in 30+ major European cities.
                                    </h2>
                                </div>
                                <p className='font-sans text-base leading-5'>
                                    The project integrated data from Kaggle and APIs like MapQuest and OpenStreetView, using technologies like D3.js, Leaflet, and SQL. The goal was to help users discover restaurants based on their preferences, using intuitive visualizations and interactive mapping.
                                </p>
                            </div>
                            <div className='overview'>
                                <h1 className='font-chivo text-base py-2 border-b-[1px] border-black mb-2' id='st-overview'>
                                    OVERVIEW
                                </h1>  
                                <div className='flex flex-row space-x-10'>
                                    <div>
                                        <h2 className='font-chivo text-xs py-1'>
                                            PROBLEM
                                        </h2>  
                                        <p className='font-sans text-base leading-5'>
                                            The available resources for discovering authentic local restaurants in major European cities were fragmented and not user-friendly. Users had to rely on scattered online reviews, maps, and lists that lacked a centralized, interactive interface for exploration.
                                        </p>
                                    </div>
                                    <div>
                                        <h2 className='font-chivo text-xs py-1'>
                                            OUTCOME
                                        </h2>  
                                        <p className='font-sans text-base leading-5'>
                                            Successfully created a user-friendly web app that allowed users to explore and filter restaurants interactively across 30+ cities in Europe. The app provided seamless navigation and visual representation of restaurant data, earning 2nd place out of 21 teams in a university competition.
                                        </p>
                                    </div>
                                </div>                           
                            </div>
                        </div>
                        <div className='w-full md:w-[240px]'>
                            <h2 className='font-chivo text-base py-2 border-b-[1px] border-black mb-2'>
                                INFO
                            </h2>
                            <div className='space-y-6'>
                                <div>
                                    <h2 className='font-chivo text-xs'>
                                        ROLE
                                    </h2>  
                                    <p className='font-sans text-base leading-5'>
                                        Developer Designer                           
                                    </p>
                                </div>
                                <div>
                                    <h2 className='font-chivo text-xs'>
                                        TOOLS
                                    </h2>  
                                    <p className='font-sans text-base leading-5'>
                                        Figma
                                    </p>   
                                    <p className='font-sans text-base leading-5'>
                                        React
                                    </p>
                                    <p className='font-sans text-base leading-5'>
                                        JavaScript
                                    </p>
                                    <p className='font-sans text-base leading-5'>
                                        Python
                                    </p>
                                    <p className='font-sans text-base leading-5'>
                                        HTML/CSS
                                    </p>
                                    <p className='font-sans text-base leading-5'>
                                        SQL
                                    </p>
                                    <p className='font-sans text-base leading-5'>
                                        D3.js
                                    </p>
                                    <p className='font-sans text-base leading-5'>
                                        Leaflet
                                    </p>
                                </div>
                                <div>
                                    <h2 className='font-chivo text-xs'>
                                        TIMELINE
                                    </h2>  
                                    <div className='flex flex-row font-sans text-base leading-5'>
                                        <p>2023</p>        
                                    </div>
                                </div>
                                <div className='flex flex-row justify-center px-2 rounded-lg border-[2px] border-black bg-[#FF83C8]'>
                                    <p className='font-sans font-medium text-base'>
                                        Github
                                    </p>
                                </div>
                            </div>  
                        </div>
                    </div>
                    <div className='mb-10 w-full rounded-lg border-2 border-black shadow-[8px_8px_0px_0px_rgba(0,0,0,1)] overflow-hidden'>   
                        <img src={TasteTest} alt="Taste Test" className="w-full h-auto object-cover" />
                    </div>
                    <div>
                        <h1 className='font-chivo text-base py-2 border-b-[1px] border-black mb-2' id='st-reflection'>
                            REFLECTION
                        </h1>
                        <div className='flex flex-row space-x-10 font-sans'>
                            <h2 className='w-1/3 text-2xl italic'>
                                Lessons learned and future improvements
                            </h2>
                            <p className='w-2/3 font-sans text-base leading-5'>
                                As the Developer and Designer leading a team of 5, I gained valuable experience in full-stack development, UI/UX design, and project management. The project challenged us to balance user experience with technical constraints while handling large datasets and complex visualizations. In future iterations, we could enhance the app by incorporating user reviews, implementing a recommendation system based on dining preferences, and expanding coverage to more cities and cuisines.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}