import React from 'react';
import { SocialIcon } from 'react-social-icons';

export function Footer(){
   return(
       <div className="pb-2 bg-[#F5F4EC]"> {/* Updated background color */}
           <div className="flex flex-col space-y-4 items-center px-4 sm:px-6 lg:px-8 py-8 text-lg md:flex-row md:justify-between">
               <div className='font-sans text-center md:text-start text-[rgb(10,11,87)]'>
                   <p className='mb-2 md:mb-0'>Contact me at <span className='font-medium underline'><a href="mailto:satperkari@gmail.com">satperkari@gmail.com</a></span>!</p>
                   <p className='text-sm italic'></p>
               </div>
               <div className='space-x-4'>
                   <SocialIcon target='_blank' style={{width: '40px', height: '40px'}} bgColor='rgb(10,11,87)' url="https://www.linkedin.com/in/sathvik-perkari-821868227"/>
                   <SocialIcon target='_blank' style={{width: '40px', height: '40px'}} bgColor='rgb(10,11,87)' url="https://github.com/satperks"/>
               </div>
           </div>
       </div>
   )
}