import { useNavigate } from "react-router-dom";

export function ProjectCard(props) {
    const navigate = useNavigate();
    const routeChange = () => {
        console.log('clicked')
        let path = props.projectPage;
        navigate(path);
    }
    
    const Chips = props.tags.map((item, index) => (
        <div key={index} className='px-1 rounded-md border-[1px] border-black'>
            <h1 className='font-sans font-medium text-[10px]'>
                {item}
            </h1>
        </div>
    ));

    const cardSize = props.isExperience 
        ? (props.isCompact 
            ? (props.sizeLarge ? "h-[180px] w-[180px]" : "h-[150px] w-[150px]")
            : "h-[300px] w-[300px]")
        : "h-[130px] w-full";

    const imgSize = props.isExperience 
        ? (props.isCompact 
            ? (props.sizeLarge ? "h-[140px] w-[140px]" : "h-[110px] w-[110px]")
            : "h-[240px] w-[240px]")
        : "h-[80px] w-[80px]";

    const cardStyle = props.backgroundImage
        ? { backgroundImage: `url(${props.backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }
        : { backgroundColor: props.color };

    return (
        <div onClick={routeChange} className={`inline-block ${props.isExperience ? "" : "w-full"}`}>
            <div className="flex flex-col">
                <div 
                    className={`${cardSize} rounded-lg border-2 border-black shadow-[4px_4px_0px_0px_rgba(0,0,0,1)]
                    flex flex-row justify-center items-center mb-2`} 
                    style={cardStyle}
                >
                    {!props.backgroundImage && (
                        <img src={props.img} alt={props.title} className={`${imgSize} rounded-lg object-contain`}/>
                    )}
                </div>
                <div className="space-y-1">
                    <div className='flex flex-row flex-wrap gap-1'>
                        {Chips}
                    </div>
                    <div className='flex flex-col'>
                        <h1 className={`font-sans font-black ${props.sizeLarge ? 'text-sm' : 'text-xs'} leading-tight`}>
                            {props.title}
                        </h1>
                        <h1 className={`font-sans font-medium ${props.sizeLarge ? 'text-xs' : 'text-[10px]'} leading-tight`}>
                            {props.company}
                        </h1>
                    </div>
                </div>
            </div>
        </div>
    );
}