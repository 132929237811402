import { useEffect, useState } from "react"
import { HashLink as Link } from 'react-router-hash-link';
import { useHeadsObserver } from "../../hooks/hooks";
import JodtVisualization from '../../imgs/jodtshowcase.jpg';
import Jodt from '../../imgs/JodtPlatform.jpg';

export function JodtPage() {
    const {activeId} = useHeadsObserver()
    const [currentSection, setCurrentSection] = useState('')

    useEffect(() =>{
        setCurrentSection(activeId)
    }, [activeId])

    const scrollWithOffset = (el) => {
        const yOffset = -145; 
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: yCoordinate, behavior: 'smooth' }); 
    }

    console.log(currentSection)

    return(
        <div className="pt-20">
            <div className='flex flex-col md:flex-row main-body items-center justify-end md:items-start md:justify-end xl:justify-center px-[110px] mb-[60px]'>
                <div className='none md:block flex flex-col space-y-4 md:space-y-4 md:items-start fixed md:left-[110px] md:top-[148px] mb-10'>
                    <div className='flex flex-col font-chivo text-base justify-center' 
                        style={currentSection === 'jodt-project' ? {textDecorationLine: 'underline'} : {textDecorationLine: 'none'}}>
                        <Link to='#jodt-project' scroll={el => scrollWithOffset(el)} onClick={() => setCurrentSection('jodt-project')}>
                            PROJECT
                        </Link>
                    </div>
                    <div className='flex flex-col font-chivo text-base justify-center'
                        style={currentSection === 'jodt-overview' ? {textDecorationLine: 'underline'} : {textDecorationLine: 'none'}}>
                        <Link to='#jodt-overview' scroll={el => scrollWithOffset(el)} onClick={() => setCurrentSection('jodt-overview')}>
                            OVERVIEW
                        </Link>
                    </div>
                    <div className='font-chivo text-base text-center'
                        style={currentSection === 'jodt-final' ? {textDecorationLine: 'underline'} : {textDecorationLine: 'none'}}>
                        FINAL PRODUCT
                    </div>
                    <div className='flex flex-col font-chivo text-base justify-center' 
                        style={currentSection === 'jodt-problem' ? {textDecorationLine: 'underline'} : {textDecorationLine: 'none'}}>
                        <Link to='#jodt-problem' scroll={el => scrollWithOffset(el)} onClick={() => setCurrentSection('jodt-problem')}>
                            PROBLEM
                        </Link>
                    </div>
                    <div className='flex flex-col font-chivo text-base justify-center'
                        style={currentSection === 'jodt-users' ? {textDecorationLine: 'underline'} : {textDecorationLine: 'none'}}>
                        USERS
                    </div>
                    <div className='flex flex-col font-chivo text-base justify-center'
                        style={currentSection === 'jodt-solution' ? {textDecorationLine: 'underline'} : {textDecorationLine: 'none'}}>
                        SOLUTION
                    </div>
                    <div className='flex flex-col font-chivo text-base justify-center'
                        style={currentSection === 'jodt-reflection' ? {textDecorationLine: 'underline'} : {textDecorationLine: 'none'}}>
                        REFLECTION
                    </div>
                </div>
                <div className='project w-[320px] md:pl-[110px] md:w-[480px] lg:w-[680px] space-y-10'>
                    <div className='mb-10 w-full rounded-lg border-2 border-black shadow-[8px_8px_0px_0px_rgba(0,0,0,1)] overflow-hidden'>   
                        <img src={JodtVisualization} alt="Jodt Visualization" className="w-full h-auto object-cover" />
                    </div>
                    <div className='flex flex-col space-y-10 md:flex-row md:space-x-10 md:space-y-0'>
                        <div className='proj&overview space-y-10'>
                            <div className='proj'>
                                <h1 className='font-chivo text-base py-2 border-b-[1px] border-black mb-2' id='jodt-project'>
                                    PROJECT
                                </h1>
                                <div className='font-sans text-2xl mb-3'>
                                    <h2 className='font-black'>
                                        Jodt: Your Efficient Internet Second Brain
                                    </h2>
                                    <h2 className='italic'>
                                        A versatile interface for quick note-taking and AI-powered insights across platforms.
                                    </h2>
                                </div>
                                <p className='font-sans text-base leading-5'>
                                    Developed Jodt, a cross-platform application that allows users to capture and organize notes quickly without leaving their current workspace. It features Retrieval-Augmented Generation (RAG) capabilities powered by GPT, helping users access insights from their notes.
                                </p>
                            </div>
                            <div className='overview'>
                                <h1 className='font-chivo text-base py-2 border-b-[1px] border-black mb-2' id='jodt-overview'>
                                    OVERVIEW
                                </h1>  
                                <div className='flex flex-row space-x-10'>
                                    <div>
                                        <h2 className='font-chivo text-xs py-1'>
                                            PROBLEM
                                        </h2>  
                                        <p className='font-sans text-base leading-5'>
                                            Users often struggle to keep track of spontaneous ideas, relevant articles, or images they want to save without interrupting their workflow. Existing note-taking solutions either lack cross-platform accessibility or fail to integrate AI tools for enhanced insights.
                                        </p>
                                    </div>
                                    <div>
                                        <h2 className='font-chivo text-xs py-1'>
                                            OUTCOME
                                        </h2>  
                                        <p className='font-sans text-base leading-5'>
                                            Successfully launched Jodt, a cross-platform application in beta, designed for quick note-taking, article saving, and image collection. Currently in beta testing for web and terminal platforms, with plans to expand into desktop and Canvas apps. The app offers a seamless experience, allowing users to save notes, ideas, and images while using generative AI to extract insights and enhance productivity.
                                        </p>
                                    </div>
                                </div>                           
                            </div>
                        </div>
                        <div className='w-full md:w-[240px]'>
                            <h2 className='font-chivo text-base py-2 border-b-[1px] border-black mb-2'>
                                INFO
                            </h2>
                            <div className='space-y-6'>
                                <div>
                                    <h2 className='font-chivo text-xs'>
                                        ROLE
                                    </h2>  
                                    <p className='font-sans text-base leading-5'>
                                        Creator
                                    </p>
                                </div>
                                <div>
                                    <h2 className='font-chivo text-xs'>
                                        TECH STACK
                                    </h2>  
                                    <p className='font-sans text-base leading-5'>
                                        React.js, HTML/CSS, JavaScript
                                    </p>   
                                    <p className='font-sans text-base leading-5'>
                                        Python (Flask)
                                    </p>
                                    <p className='font-sans text-base leading-5'>
                                        SQLite, PostgreSQL
                                    </p>
                                    <p className='font-sans text-base leading-5'>
                                        OpenAI GPT-4 API
                                    </p>
                                    <p className='font-sans text-base leading-5'>
                                        Langchain
                                    </p>
                                    <p className='font-sans text-base leading-5'>
                                        Elasticsearch
                                    </p>
                                    <p className='font-sans text-base leading-5'>
                                        Figma, GitHub, Docker
                                    </p>
                                </div>
                                <div>
                                    <h2 className='font-chivo text-xs'>
                                        TIMELINE
                                    </h2>  
                                    <div className='flex flex-row font-sans text-base leading-5'>
                                        <p>2024 (Ongoing)</p>        
                                    </div>
                                </div>
                                <div className='flex flex-row justify-center px-2 rounded-lg border-[2px] border-black bg-[#FF83C8]'>
                                    <p className='font-sans font-medium text-base'>
                                        Github
                                    </p>
                                </div>
                            </div>  
                        </div>
                    </div>
                    <div className='mb-10 w-full rounded-lg border-2 border-black shadow-[8px_8px_0px_0px_rgba(0,0,0,1)] overflow-hidden'>   
                        <img src={Jodt} alt="Jodt" className="w-full h-auto object-cover" />
                    </div>
                    <div>
                        <h1 className='font-chivo text-base py-2 border-b-[1px] border-black mb-2' id='jodt-reflection'>
                            REFLECTION
                        </h1>
                        <div className='flex flex-row space-x-10 font-sans'>
                            <h2 className='w-1/3 text-2xl italic'>
                                Lessons learned and future improvements
                            </h2>
                            <p className='w-2/3 font-sans text-base leading-5'>
                                As the Creator of Jodt, I gained valuable experience in full-stack development, UI/UX design, and integrating AI technologies. The project challenged me to balance user experience with technical constraints while handling complex data processing and AI integration. In future iterations, we could enhance the app by expanding to more platforms, improving the AI's contextual understanding, and implementing advanced features like collaborative note-sharing and real-time synchronization across devices.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}