import React, { useEffect } from 'react';

function PhoneScroll({ image }) {
    const styles = `
    .iphone-frame {
        width: 390px;
        height: 844px;
        background-color: #fff;
        border-radius: 50px;
        border: 16px solid #fff;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        overflow: hidden;
    }

    .screen {
        width: 100%;
        height: 100%;
        border-radius: 45px;
        overflow-y: scroll;
        background-color: #fff;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .screen::-webkit-scrollbar {
        display: none;
    }

    .content-image {
        width: 100%;
        object-fit: contain;
        display: block;
        margin: 0 auto;
    }
    `;

    useEffect(() => {
        const styleSheet = document.createElement("style");
        styleSheet.type = "text/css";
        styleSheet.innerText = styles;
        document.head.appendChild(styleSheet);

        return () => {
            document.head.removeChild(styleSheet);
        };
    }, []);

    return (
        <div className="iphone-frame">
            <div className="screen">
                <img src={image} alt="Phone Screen Content" className="content-image" />
            </div>
        </div>
    );
}

export default PhoneScroll;